const BASE_URL = `${process.env.VUE_APP_API_URL}/api/v3`;
const BASE_URL_v2 = `${process.env.VUE_APP_API_URL}/api/v2`;
const BASE_URL_V4 = `${process.env.VUE_APP_API_URL}/api/v4`;

// Auth
export const API_AUTH = {
    LOGIN: `${BASE_URL}/auth/login-email-employer`,
    LOGOUT: `${BASE_URL}/auth/logout`,
    GET_PROFILE: `${BASE_URL}/user/profile-employer`,
    GET_ROLES: `${BASE_URL}/partner/dashboard/user/roles`,
    VALIDATE_KEY_AUTH: `${BASE_URL_V4}/auth/validate-secure-auth`,
    VALIDATE_OTP_AUTH: `${BASE_URL_V4}/auth/verify-otp-email`,
    SEND_OTP_CODE: `${BASE_URL_V4}/auth/send-otp-email`,
};

// Company
export const API_COMPANY = {
    PATCH_CORRECTION_LIMIT: `${BASE_URL}/partner/dashboard/attendance-setting/attendance-correction-limit`
};

// Company Office
export const API_COMPANY_OFFICE = {
    LIST: `${BASE_URL}/partner/company-office`,
    LIST_ITEM: id => `${BASE_URL}/partner/company-office/${id}`,
    LIST_PAGINATE: `${BASE_URL}/partner/dashboard/company-office`,
    PATCH_ATTENDANCE: id => `${BASE_URL}/partner/dashboard/company-office/attendance-method/${id}`,
};

// Option Data
export const OPTION_DATA = {
    ROLE: `${BASE_URL}/partner/company-role`,
    ROLE_BY_OFFICE: `${BASE_URL}/partner/dashboard/company-office/get-used-role-list`,
    OPEN_ROLE: `${BASE_URL}/partner/dashboard/initiation/role/open`,
    OFFICE_ROLE: (id) => `${BASE_URL}/partner/company-role/office-role/${id}`,
    COMPANY: `${BASE_URL}/partner/dashboard/company/list`,
    SHIFT: `${BASE_URL}/partner/dashboard/company-shift`,
    GET_ALL_SHIFT_BY_COMPANY: (company_id) => `${BASE_URL}/partner/dashboard/company-shift/all-by-company/${company_id}`,
    GET_ALL_SHIFT_BY_OFFICE: (company_office_id) => `${BASE_URL}/partner/dashboard/company-shift/all/${company_office_id}`,
    SHIFT_ITEM: id => `${BASE_URL}/partner/dashboard/company-shift/${id}`,
    CHECK_SHIFT: id => `${BASE_URL}/partner/dashboard/company-shift/check-exists/${id}`,
    JOB_TYPE: `${BASE_URL}/job/type`,
    EDUCATION_LEVEL: `${BASE_URL}/user/education-level`,
    TEST_CATEGORY: `${BASE_URL}/assessment/list/category`,
    JOB_PREFERENCE: `${BASE_URL}/user/job-preference`,
    CITY: `${BASE_URL}/geograph/city`,
    PROVINCE: `${BASE_URL}/geograph/province`,
    GET_ALL_COMPANY_ROLES: `${BASE_URL_V4}/partner/dashboard/company-role/list`,
    GET_COMPANY_OFFICE_LIST: `${BASE_URL_V4}/partner/dashboard/company-office/list`
};

// Mitra
export const MITRA = {
    RECAP: `${BASE_URL}/partner/dashboard/recap`,
    SHOW_MITRA_ONBOARDING: source => `${BASE_URL}/partner/dashboard/onboarding/${source}`,
    MANUAL_INPUT_ONBOARDING: `${BASE_URL}/partner/dashboard/onboarding/manual-input`,
    ALL_RECAP: `${BASE_URL}/partner/dashboard/recap/all-data`,
    ALL_RECAP_GRABWHEELS: `${BASE_URL}/partner/dashboard/recap/all-data`,
    ALL_RECAP_NON_GROUPED: `${BASE_URL}/partner/dashboard/recap/all-data-non-grouped`,
    ALL_RECAP_NON_GROUPED_GRABWHEELS: `${BASE_URL}/partner/dashboard/recap/all-data-non-grouped`,
    WEEKLY_REPORT: `${BASE_URL}/partner/dashboard/recap/all-data/by-month`,
    WEEKLY_REPORT_BY_MONTH: `${BASE_URL}/partner/dashboard/recap/weekly-by-month`,
    DAILY_REPORT_BY_MONTH: `${BASE_URL}/partner/dashboard/recap/daily-by-month`,
    DAILY_REPORT_BY_MONTH_GRABWHEELS: `${BASE_URL}/partner/dashboard/recap/daily-by-month`,
    ALL_RECAP_PAGINATE_V2: `${BASE_URL_v2}/mitra/recap/all-data-paginate`,
    ALL_RECAP_ATTENDANCE_DETAIL: `${BASE_URL}/partner/dashboard/recap/all-data-attendance-detail`,
    ALL_RECAP_ATTENDANCE: `${BASE_URL}/partner/dashboard/recap/all-data-attendance`,
    ALL_RECAP_PAGINATE: `${BASE_URL}/partner/dashboard/recap/all-data-paginate`,
    ALL_RECAP_PAGINATE_GRABWHEELS: `${BASE_URL}/partner/dashboard/recap/all-data-paginate`,
    UPDATE_MULTIPLE_MITRA_ATTENDANCE: `${BASE_URL}/partner/dashboard/recap/multiple/lock`,
    UPDATE_SINGLE_MITRA_ATTENDANCE: `${BASE_URL}/partner/dashboard/recap/single/lock`,
    UPLOAD_ATTENDANCE_VERIFICATION: `${BASE_URL}/partner/dashboard/recap/upload-attendance-verification`,
    RECAP_DETAIL: id => `${BASE_URL}/partner/dashboard/recap/${id}`,
    RECAP_DETAIL_GRABWHEELS: id => `${BASE_URL}/partner/dashboard/recap/${id}`,
    DOWNLOAD_TEMPLATE_OR_CREATE_PAYSLIP_BATCH: `${BASE_URL}/partner/dashboard/payslip/batch`,
    PAYSLIP_BATCH_LIST: `${BASE_URL}/partner/dashboard/payslip/batch`,
    PAYSLIP_BATCH_LIST_NAME: `${BASE_URL}/partner/dashboard/payslip/batch/list`,
    COPY_FIELD_PAYSLIP_BATCH: (batch_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/copy-field`,
    DELETE_PAYSLIP_BATCH: id => `${BASE_URL}/partner/dashboard/payslip/batch/${id}`,
    SHOW_PAYSLIP_BATCH_DETAIL: id => `${BASE_URL}/partner/dashboard/payslip/batch/${id}`,
    UPLOAD_EXCEL_PAYSLIP: id => `${BASE_URL}/partner/dashboard/payslip/batch/${id}/upload`,
    DOWNLOAD_PAYSLIP_BATCH_EXCEL: id => `${BASE_URL}/partner/dashboard/payslip/batch/${id}/download`,
    EDIT_PAYSLIP_ON_BATCH: (batch_id, slip_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/slip/${slip_id}`,
    DELETE_PAYSLIP_ON_BATCH: (batch_id, slip_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/slip/${slip_id}`,
    PAYSLIP_BATCH_ERROR_LOGS: id => `${BASE_URL}/partner/dashboard/payslip/batch/${id}/logs`,
    SHOW_IDENTITY_DOC: id => `${BASE_URL}/partner/user-detail/identity-document/${id}`,
    SHOW_FAMILY_DETAIL: id => `${BASE_URL}/partner/user-detail/family/${id}`,
    SHOW_EQUIPMENT_DETAIL: id => `${BASE_URL}/partner/user-detail/equipment/${id}`,
    SHOW_DOCUMENT: id => `${BASE_URL}/partner/user-detail/document/${id}`,
    SHOW_EMERGENCY_CONTACT: id => `${BASE_URL}/partner/user-detail/emergency-contact/${id}`,
    SHOW_JOB_DETAIL: id => `${BASE_URL}/partner/user-detail/job/${id}`,
    SHOW_EDUCATION: id => `${BASE_URL}/partner/user-detail/education/${id}`,
    SHOW_EXPERIENCE: id => `${BASE_URL}/partner/user-detail/experience/${id}`,
    SHOW_TES_RESULT: id => `${BASE_URL}/partner/user-detail/test-score/${id}`,
    SHOW_ADDITIONAL_QUESTIONS: id => `${BASE_URL}/partner/user-detail/additional-questions/${id}`,
    SHOW_PROFILE: id => `${BASE_URL}/partner/user-detail/profile/${id}`,
    SHOW_SALARY_DATA: id => `${BASE_URL}/partner/user-detail/payment/${id}`,

    // Payslip v2
    EDIT_PAYSLIP_BATCH_TEMPLATE: (batch_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/template`,
    DOWNLOAD_PAYSLIP_BATCH_TEMPLATE: (batch_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/template/download`,
    ADD_MITRA_PAYSLIP: (batch_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/insert`,

    // Payslip v3
    SET_FIELD_AS_HIGHLIGHT: (batch_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/set-highlight`,
    REMOVE_FIELD_AS_HIGHLIGHT: (batch_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/remove-highlight`,
    DISTRIBUTE_PAYSLIP_BATCH: (batch_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/distribute`,
    DISTRIBUTE_PAYSLIP: (batch_id, slip_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/slip/${slip_id}/distribute`,
    EDIT_PAYSLIP_BATCH_WARNING_LETTER: (batch_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/warning-letter`,
    EDIT_PAYSLIP_BATCH_FIELD: (batch_id) => `${BASE_URL}/partner/dashboard/payslip/batch/${batch_id}/field`
};

// Schedule
export const SCHEDULE = {
    CREATE: `${BASE_URL}/partner/dashboard/schedule-shift`,
    GET_CREATE_GRABWHEELS: `${BASE_URL}/partner/dashboard/grab-wheels/daily-schedule`,
    EDIT_DELETE_GRABWHEELS: id => `${BASE_URL}/partner/dashboard/grab-wheels/daily-schedule/${id}`,
    EDIT: `${BASE_URL}/partner/dashboard/schedule-shift`,
    EDIT_SPECIFIC: (id, date) => `${BASE_URL}/partner/dashboard/schedule-shift/${id}/specific?date=${date}`,
    SHOW_SCHEDULE_SHIFT: `${BASE_URL}/partner/dashboard/schedule-shift`,
    GET_SCHEDULE_SHIFT: `${BASE_URL}/partner/dashboard/schedule-shift`,
    GET_ALL_COMPANY_SHIFT: `${BASE_URL}/partner/dashboard/company-shift/all`,
    GET_USER_PARTNER: `${BASE_URL}/partner/dashboard/user-partner/all`,
    GET_COMPANY_ROLE: `${BASE_URL}/partner/company-role`,
    GET_SCHEDULE_NAME: `${BASE_URL}/partner/dashboard/schedule-shift/name`,
    POST_NOTIF: `${BASE_URL}/partner/dashboard/schedule-shift`,
    CHECK_AVAILABLE: `${BASE_URL}/partner/dashboard/schedule-shift/check-exists`,
};

// Recap
export const API_COMPANY_RECAP = {
    LIST: `${BASE_URL}/partner/dashboard/recap-worker-schedule`,
    LIST_GRABWHEELS: `${BASE_URL}/partner/dashboard/grab-wheels/schedule-recap`,
};

// Initiation
export const INITIATION = {
    LIST: `${BASE_URL}/partner/dashboard/initiation`,
    GET_TEMPLATE_FULL_MIGRATION: office_id => `${BASE_URL}/partner/dashboard/initiation/list-per-office?company_office_id=${office_id}`,
    COMPANY_LIST: `${BASE_URL}/partner/dashboard/initiation/company`,
    POST_FULL_MIGRATION: office_id => `${BASE_URL}/partner/dashboard/migration/${office_id}`,
    SALARY_SETUP: `${BASE_URL}/partner/dashboard/initiation/company-role-office-setting`,
    POST_JOB: id => `${BASE_URL}/partner/dashboard/initiation/${id}/job`,
    POST_MULTI_JOB: `${BASE_URL}/partner/dashboard/initiation/multi-initiation`,
    POST_IMPORT_MITRA: job_id => `${BASE_URL}/partner/dashboard/candidate/upload/job-vacancy/${job_id}`,
    POST_MANUAL_INPUT: job_id => `${BASE_URL}/partner/dashboard/migration/manual-input/${job_id}`,
    EDIT_DETAIL_JOB: id => `${BASE_URL}/partner/dashboard/initiation/job/${id}`,
    JOB_LIST: `${BASE_URL}/partner/dashboard/initiation/job`,
    AUTO_ASSIGN_MITRA: job_id => `${BASE_URL}/partner/dashboard/initiation/auto-assign-mitra/job/${job_id}`,
    GET_OFFICE_BY_ROLE: (id) => `${BASE_URL}/partner/dashboard/initiation/list/${id}`,
    GET_ADDITIONAL_DOCUMENT: `${BASE_URL}/partner/dashboard/initiation/other-doc/list`,

    JOB_LIST_V4: `${BASE_URL_V4}/partner/dashboard/initiation/job`,
    FILTER_V4: `${BASE_URL_V4}/partner/dashboard/initiation/filter-options`,
    UPDATE_STATUS_V4: (id) => `${BASE_URL_V4}/partner/dashboard/initiation/${id}/status`,
    SEARCH_PIC_V4: `${BASE_URL_V4}/partner/dashboard/initiation/pic`,
    ALL_JOB_LIST_V4: `${BASE_URL_V4}/partner/dashboard/initiation/all/job`,
    MULTIPLE_UPDATE_STATUS_V4: `${BASE_URL_V4}/partner/dashboard/initiation/multiple-update-status`
};

// Report
export const API_REPORT = {
    MITRA: `${BASE_URL}/partner/dashboard/report/mitra`,
    OFFICE_LIST: `${BASE_URL}/partner/dashboard/report/company/office`,
    INITIATION: `${BASE_URL}/partner/dashboard/report/init-job`,
    INITIATION_FULFILLMENT: `${BASE_URL}/partner/dashboard/report/init-fulfillment`,
    DAILY_RECAP_WORKER_ATTENDANCE_LIST: `${BASE_URL}/partner/dashboard/report/daily-recap-worker-attendance`,
    REIMBURSEMENT: `${BASE_URL}/partner/dashboard/report/reimbursement`,
    SUMMARY_ATTENDANCE_LIST: `${BASE_URL}/partner/dashboard/report/summary-attendance`,
    SUMMARY_ATTENDANCE_DETAIL: (id) => `${BASE_URL}/partner/dashboard/report/summary-attendance/detail/${id}`,
    SUMMARY_ATTENDANCE_WORKER_LIST: (id) => `${BASE_URL}/partner/dashboard/report/summary-attendance/worker-list/${id}`,
    SUMMARY_ATTENDANCE_WORKER_LIST_REPORT: (id) => `${BASE_URL}/partner/dashboard/report/summary-attendance/worker-list-report/${id}`,
    DAILY_ATTENDANCE: `${BASE_URL}/partner/dashboard/report/daily-attendance`,
    USER_ATTENDANCE: `${BASE_URL}/partner/dashboard/report/user-attendance`,
    WEEKLY_SHIFT_REPORT: `${BASE_URL}/partner/dashboard/report/weekly-shift`
};

// Applicant
export const APPLICANT = {
    LIST: `${BASE_URL}/partner/dashboard/job-applicant`,
    LIST_DETAIL_JOB: `${BASE_URL}/partner/dashboard/job-applicant/detail-job-applicant`,
    DETAIL: id => `${BASE_URL}/partner/dashboard/job-applicant/${id}`,
    PICKUP: id => `${BASE_URL}/partner/dashboard/job-applicant/${id}/pickup`,
    MULTI_PICKUP: `${BASE_URL}/partner/dashboard/job-applicant/pickup/multi`,
    REJECT: id => `${BASE_URL}/partner/dashboard/job-applicant/${id}/reject`,
    MULTI_REJECT: `${BASE_URL}/partner/dashboard/job-applicant/reject/multi`,
    INVITE_INTERVIEW: id => `${BASE_URL}/partner/dashboard/job-applicant/${id}/invite-interview`,
    MULTI_INVITE_INTERVIEW: `${BASE_URL}/partner/dashboard/job-applicant/invite-interview/multi`,
    INTERVIEWED: id => `${BASE_URL}/partner/dashboard/job-applicant/${id}/interviewed`,
    MULTI_INTERVIEWED: `${BASE_URL}/partner/dashboard/job-applicant/interviewed/multi`,
    SENT_CONTRACT: id => `${BASE_URL}/partner/dashboard/job-applicant/${id}/sent-contract`,
    MULTI_SENT_CONTRACT: `${BASE_URL}/partner/dashboard/job-applicant/sent-contract/multi`,
    RESENT_CONTRACT: id => `${BASE_URL}/partner/dashboard/job-applicant/${id}/resent-contract`,
    LIST_WHATSAPP_TEMPLATE: `${BASE_URL}/partner/dashboard/job-applicant/whatsapp-template`,
};

// Job Applicant
export const JOB_APPLICANT = {
    DASHBOARD: (province_id) => `${BASE_URL}/partner/dashboard/job-applicant/dashboard/${province_id}`,
    LIST_JOB: `${BASE_URL}/partner/dashboard/job-applicant/list`,
    LIST_APPLICANT: (job_id) => `${BASE_URL}/partner/dashboard/job-applicant/${job_id}/list`,
    ALL_LIST_APPLICANT: (job_id) => `${BASE_URL}/partner/dashboard/job-applicant/${job_id}/all`,
    REPORT_LIST_APPLICANT: (job_id) => `${BASE_URL}/partner/dashboard/job-applicant/${job_id}/report`,
    DETAIL_CANDIDATE: (user_id) => `${BASE_URL_V4}/partner/dashboard/job-applicant/${user_id}`,
    DETAIL_OTHER_JOB: (user_id) => `${BASE_URL_V4}/partner/dashboard/job-applicant/${user_id}/all-application`,
    DOWNLOAD_CV_APPLICANT: (user_id) => `${BASE_URL}/partner/dashboard/candidate/cv?user_id=${user_id}`,
    INTERVIEW_SCHEDULE_LIST: companyId => `${BASE_URL_V4}/partner/dashboard/job-applicant/${companyId}/interview-schedule`,
    REPORT_ALL_APPLICANT: `${BASE_URL_V4}/partner/dashboard/job-applicant/report`,
    
    // V4
    LIST_APPLICANT_V4: `${BASE_URL_V4}/partner/dashboard/job-applicant/detail/list`,
    ALL_LIST_APPLICANT_V4: `${BASE_URL_V4}/partner/dashboard/job-applicant/detail/list-all`
};

// Job Preference
export const JOB_PREFERENCE = {
    LIST: `${BASE_URL}/job-preference`,
};

// Job
export const JOB = {
    LIST: `${BASE_URL_V4}/partner/dashboard/job/list`
};

// Candidate
export const CANDIDATE = {
    LIST: `${BASE_URL}/partner/dashboard/candidate`,
    ALL: `${BASE_URL}/partner/dashboard/candidate/all`,
    GET_CV: (filter, data) => `${BASE_URL}/partner/dashboard/candidate/cv/${filter}/${data}`,
    INVITE_TO_JOB: (user_id, type, job_id) => `${BASE_URL}/partner/dashboard/candidate/invite/${user_id}/${type}/${job_id}`,
    INVITE_MULTI_CANDIDATE: (type, job_id) => `${BASE_URL}/partner/dashboard/candidate/invite/${type}/${job_id}`,
    DOWNLOAD_CV: `${BASE_URL}/partner/dashboard/candidate/cv`,
};

// Workers
export const WORKER = {
    LIST_ALL: `${BASE_URL}/partner/dashboard/user-partner/all`,
    ALL_WORKER: `${BASE_URL}/partner/dashboard/user-partner/mitra-list/all`,
    LIST: `${BASE_URL}/partner/dashboard/user-partner/mitra-list`,
    MITRA_DETAIL: (id) => `${BASE_URL}/partner/dashboard/user-partner/mitra-list/${id}`,
    SALARY: `${BASE_URL}/partner/dashboard/user-partner/mitra-salary`,
    // DELETE_MITRA: id => `${BASE_URL}/partner/dashboard/user-partner/${id}`,
    DELETE_MITRA: `${BASE_URL}/partner/dashboard/user-partner/delete-mitra`,
    HOLDUNHOLD: (id) => `${BASE_URL}/partner/dashboard/user-partner/hold-unhold/${id}`,
    HOLD_UNHOLD_MULTIPLE: `${BASE_URL}/partner/dashboard/user-partner/hold-unhold`,
    MOVE_CHANGE_MITRA: (id) => `${BASE_URL}/partner/dashboard/user-partner/move-or-change/${id}`,
    MOVE_MULTIPLE_MITRA: `${BASE_URL}/partner/dashboard/user-partner/move-mitra`,
    LIST_CONTRACT_BY_USER: user_id => `${BASE_URL}/partner/dashboard/contract/pdf-contract/${user_id}`,
    DOWNLOAD_CONTRACT: (sign_contract_id, user_id, job_partner_id) => `${BASE_URL}/partner/dashboard/contract/generate/pdf-contract/${sign_contract_id}/${user_id}/${job_partner_id}`,
    DOWNLOAD_SIGNATURE: (sign_contract_id, user_id, job_partner_id) => `${BASE_URL}/partner/dashboard/contract/generate/pdf-signature-certificate/${sign_contract_id}/${user_id}/${job_partner_id}`,
    DOWNLOAD_UNSIGNED_CONTRACT: (sign_contract_id, user_id, job_partner_id) =>
        `${BASE_URL}/partner/dashboard/contract/generate/pdf-unsigned-contract/${sign_contract_id}/${user_id}/${job_partner_id}`,
    RENEW_CONTRACT_MITRA: (id) => `${BASE_URL}/partner/dashboard/user-partner/renew/${id}`,
    RENEW_MULTI_CONTRACT_MITRA: `${BASE_URL}/partner/dashboard/user-partner/renew`,
    DOWNLOAD_MITRA_DATA: `${BASE_URL}/partner/dashboard/user-partner/mitra/download`,
    UPLOAD_MITRA_DATA: (type, source) => `${BASE_URL}/partner/dashboard/user-partner/mitra/upload/${type}${source ? `?source=${source}` : '' }`,
    RESEND_EMAIL: `${BASE_URL}/partner/dashboard/user-partner/email`,
    DELETE_PARTNER: (user_id) => `${BASE_URL}/partner/dashboard/onboarding/${user_id}`,
    DOWNLOAD_ONBOARDING_MITRA_DATA: (source) => `${BASE_URL}/partner/dashboard/onboarding/download/${source}`,
    REBLAST_NOTIFICATION: (user_id) => `${BASE_URL}/partner/user-detail/email/${user_id}`,
};

// Ex Mitra
export const EXMITRA = {
    LIST: `${BASE_URL}/partner/dashboard/user-partner/ex-mitra-list`,
    EX_MITRA_CV_DETAIL: (id) => `${BASE_URL}/partner/dashboard/user-partner/ex-mitra-list/cv-detail/${id}`,
    REACTIVE: (id) => `${BASE_URL}/partner/dashboard/user-partner/reactive-ex-mitra/${id}`
};

// Contract
export const CONTRACT = {
    LIST: `${BASE_URL}/partner/dashboard/contract`,
    DETAIL: id => `${BASE_URL}/partner/dashboard/contract/${id}`,
    ALL: `${BASE_URL}/partner/dashboard/contract/all`,
    SELECTABLE: `${BASE_URL}/partner/dashboard/contract/selectable`,
    JOB_VACANCY_CONTRACTS: `${BASE_URL}/partner/dashboard/contract/job-vacancy-contracts`,
};

// Profile
export const PROFILE = {
    GET_UPDATE: `${BASE_URL}/partner/dashboard/user/profile`,
    SWITCH_COMPANY: `${BASE_URL}/partner/dashboard/user/switch-company`,
    UPLOAD_IMAGE: `${BASE_URL}/file/image/profile`,
    NOTIFICATION: `${BASE_URL}/user/notification`,
    COUNTER: `${BASE_URL}/user/notification/counter`,
    READ_ALL_NOTIFICATION: `${BASE_URL}/user/notification-all`,
};

// Announcement
export const ANNOUNCEMENT = {
    LIST_CREATE: `${BASE_URL}/partner/dashboard/announcement`,
    GET_UPDATE_DELETE: id => `${BASE_URL}/partner/dashboard/announcement/${id}`,
};

// Salary
export const SALARY = {
    // OLD SALARY
    // CHECK_RECAPS: `${BASE_URL}/partner/dashboard/salary/check-recaps`,
    // CREATE_BATCH: `${BASE_URL}/partner/dashboard/salary/create-batch`,
    // DETAIL: id => `${BASE_URL}/partner/dashboard/salary/${id}`,
    // UPDATE_STATUS: `${BASE_URL}/partner/dashboard/salary/status`,
    // EXPORT: id => `${BASE_URL}/partner/dashboard/salary/export/${id}`,
    // ADD_REFERENCE: `${BASE_URL}/partner/dashboard/salary/add-reference`,
    // RECAP_DETAIL: id => `${BASE_URL}/partner/dashboard/salary/detail/${id}`,
    // END OF OLD SALARY
    GET_SALARIES: `${BASE_URL}/partner/dashboard/salary`,
    DETAIL_SALARY: `${BASE_URL}/partner/dashboard/salary/detail`,
    GET_SALARY_REPORTS: `${BASE_URL}/partner/dashboard/salary/report`,
    GET_SALARY_REPORT_LIST: `${BASE_URL}/partner/dashboard/salary/report/list`,
    DETAIL_SALARY_REPORT: `${BASE_URL}/partner/dashboard/salary/report/detail`,
    GENERATE_SALARY: `${BASE_URL}/partner/dashboard/salary/generate`,
    TRANSFER_SALARY: `${BASE_URL}/partner/dashboard/salary/transfer`,
    EDIT_FEE: id => `${BASE_URL}/partner/dashboard/salary/edit-fee/${id}`,
};

// Company leave
export const COMPANY_LEAVE = {
    LIST_MITRA: `${BASE_URL}/partner/dashboard/leave/list`,
    DETAIL_MITRA: id => `${BASE_URL}/partner/dashboard/leave/partner/${id}`,
    LIST_LEAVE_PER_MITRA: id => `${BASE_URL}/partner/dashboard/leave/${id}/leave-list`,
    APPROVE_REJECT: id => `${BASE_URL}/partner/dashboard/leave/request/${id}`
};

// Salary Adjustment
export const SALARY_ADJUSTMENT = {
    GET: id => `${BASE_URL}/partner/dashboard/salary-adjustment/${id}`,
    CREATE: id => `${BASE_URL}/partner/dashboard/salary-adjustment/${id}/create`,
    EDIT: id => `${BASE_URL}/partner/dashboard/salary-adjustment/${id}/edit`,
    DELETE: id => `${BASE_URL}/partner/dashboard/salary-adjustment/${id}/delete`,
    GET_CASH_ADVANCE: `${BASE_URL}/partner/dashboard/salary-adjustment/cash-advance`,
    DETAIL_CASH_ADVANCE: id => `${BASE_URL}/partner/dashboard/salary-adjustment/cash-advance/${id}`,
    APPROVE_CASH_ADVANCE: id => `${BASE_URL}/partner/dashboard/salary-adjustment/cash-advance/${id}/approve`,
    REJECT_CASH_ADVANCE: id => `${BASE_URL}/partner/dashboard/salary-adjustment/cash-advance/${id}/reject`,
    TRANSFER_CASH_ADVANCE: id => `${BASE_URL}/partner/dashboard/salary-adjustment/cash-advance/${id}/transfer`,
    GET_REIMBURSEMENT: `${BASE_URL}/partner/dashboard/salary-adjustment/reimbursement`,
    DETAIL_REIMBURSEMENT: id => `${BASE_URL}/partner/dashboard/salary-adjustment/reimbursement/${id}`,
    APPROVE_REIMBURSEMENT: id => `${BASE_URL}/partner/dashboard/salary-adjustment/reimbursement/${id}/approve`,
    APPROVE_REIMBURSEMENT_VOUCHER: id => `${BASE_URL}/partner/dashboard/salary-adjustment/reimbursement-voucher/${id}/approve`,
    REJECT_REIMBURSEMENT: id => `${BASE_URL}/partner/dashboard/salary-adjustment/reimbursement/${id}/reject`,
    TRANSFER_REIMBURSEMENT: id => `${BASE_URL}/partner/dashboard/salary-adjustment/reimbursement/${id}/transfer`
};

export const TASK = {
    TASK_ASSIGNMENT_LIST: `${BASE_URL}/partner/dashboard/task-assignment`,
    ADD_ASSIGNMENT: `${BASE_URL}/partner/dashboard/task-assignment`,
    UPDATE_ASSIGNMENT: id => `${BASE_URL}/partner/dashboard/task-assignment/${id}`,

};
// Profile
export const DASHBOARD = {
    GET_DATA: `${BASE_URL}/partner/dashboard`,
};

// New shift
export const NEW_SHIFT = {
    GET_COMPANY_OFFICE: `${BASE_URL}/partner/company-office`,
    GET_SHIFT_LIST: `${BASE_URL}/partner/dashboard/company-shift/shift/list`,
    GET_MITRA_LIST: `${BASE_URL}/partner/dashboard/company-shift/mitra/list`,
    SHOW_COMPANY_SHIFT: (shift_id) => `${BASE_URL}/partner/dashboard/company-shift/detail-shift/${shift_id}`,
    ADD_SHIFT: `${BASE_URL}/partner/dashboard/company-shift/add-shift`,
    EDIT_SHIFT: (shift_id) => `${BASE_URL}/partner/dashboard/company-shift/edit-shift/${shift_id}`,
    DELETE_SHIFT: (shift_id) => `${BASE_URL}/partner/dashboard/company-shift/delete-shift/${shift_id}`,
    DELETE_MITRA_SHIFT: (job_partner_id) => `${BASE_URL}/partner/dashboard/company-shift/delete-mitra-shift/${job_partner_id}`,
    MOVE_MITRA_SHIFT: (job_partner_id) => `${BASE_URL}/partner/dashboard/company-shift/move-mitra-shift/${job_partner_id}`,
    ADD_MITRA_SHIFT: `${BASE_URL}/partner/dashboard/company-shift/add-mitra-shift`,
    GET_SHIFT_LIST_NO_PAGINATE: `${BASE_URL}/partner/dashboard/company-shift/shift/list-no-paginate`
};

// Astro shift
export const DAILY_SHIFT = {
    GET_SHIFT_LIST: `${BASE_URL}/partner/dashboard/astro/schedule-shift/shift/list`,
    GET_MITRA_LIST: `${BASE_URL}/partner/dashboard/astro/schedule-shift/mitra/list`,
    GET_DETAIL_SHIFT: (shift_id) => `${BASE_URL}/partner/dashboard/astro/schedule-shift/detail-shift/${shift_id}`,
    ADD_SHIFT: `${BASE_URL}/partner/dashboard/astro/schedule-shift`,
    EDIT_SHIFT: (shift_id) => `${BASE_URL}/partner/dashboard/astro/schedule-shift/edit/${shift_id}`,
    DELETE_SHIFT: (shift_id) => `${BASE_URL}/partner/dashboard/astro/schedule-shift/delete/${shift_id}`,
    ASSIGN_MITRA_SHIFT: `${BASE_URL}/partner/dashboard/astro/schedule-shift/mitra/assign-shift`,
    UPLOAD_SHIFT_TEMPLATE: `${BASE_URL}/partner/dashboard/astro/shift-report/upload-shift`,
    DOWNLOAD_SHIFT_TEMPLATE: `${BASE_URL}/partner/dashboard/astro/shift-report/download-shift`,
    GET_UPLOAD_SHIFT_ERROR_LOGS: `${BASE_URL}/partner/dashboard/astro/shift-report/upload-shift/logs`,
    GET_SHIFT_RECAP_DATE: `${BASE_URL}/partner/dashboard/astro/schedule-shift/recap-shift-date`,
    GET_SHIFT_RECAP_USER: `${BASE_URL}/partner/dashboard/astro/schedule-shift/recap-shift-user`,
    MONTHLY_SHIFT_REPORT: `${BASE_URL}/partner/dashboard/astro/shift-report/month`,
};

export const SETTING_ACCESS = {
    GET_ROLE_LIST: `${BASE_URL}/partner/dashboard/access/roles`,
    GET_ROLE_DETAIL: (role_id) => `${BASE_URL}/partner/dashboard/access/roles/${role_id}`,
    UPDATE_OR_CREATE_ROLE:  `${BASE_URL}/partner/dashboard/access/roles`,
};

// Daily recap worker attendance
export const API_DAILY_RECAP_WORKER_ATTENDANCE = {
    RECAP: `${BASE_URL}/partner/dashboard/daily-recap-worker-attendance/recap`,
    RECAP_PER_QUERY: `${BASE_URL}/partner/dashboard/daily-recap-worker-attendance/recap-per-query`,
    RECAP_SUMMARY_ATTENDANCE: `${BASE_URL}/partner/dashboard/daily-recap-worker-attendance/recap-summary-attendance`,
    RECAP_SUMMARY_ATTENDANCE_PER_QUERY: `${BASE_URL}/partner/dashboard/daily-recap-worker-attendance/recap-summary-attendance-per-query`,
};
export const ATTENDANCE_SETTING = {
    SELF_SCAN_SETTING: `${BASE_URL}/partner/dashboard/attendance-setting/self-scan`,
};

// Salary settings
export const SALARY_SETTINGS = {
    REIMBURSEMENT_LIST: `${BASE_URL}/partner/dashboard/salary-setting/reimbursement-setup`,
    REIMBURSEMENT_DETAIL: (id) => `${BASE_URL}/partner/dashboard/salary-setting/reimbursement-setup/${id}`
};

// SP notice
export const SP_REQUEST = {
    SP_DETAIL: (id) => `${BASE_URL}/partner/dashboard/sp-request/${id}`,
    SP_SEND: (id) => `${BASE_URL}/partner/dashboard/sp-request/send/${id}`,
    SP_CANCEL: (id) => `${BASE_URL}/partner/dashboard/sp-request/cancel/${id}`
};

export const FILE_UPLOAD = {
    UPLOAD_ATTACHMENT: (api) => `${BASE_URL}/file/${api}`
};

export const OTHER_DOCUMENT = {
    UPLOAD_DOCUMENT: (api, user_id) => `${BASE_URL}/file/cv-other-document/${api}/${user_id}`,
    VALIDATE: (user_id, document) => `${BASE_URL}/partner/user-detail/document/validate/${user_id}/${document}`,
    VALIDATE_MULTIPLE: (user_id) => `${BASE_URL}/partner/user-detail/document/multi-validate/${user_id}`,
};

// Tax
export const TAX = {
    TAX_DEDUCTION_BATCH_LIST: `${BASE_URL}/partner/dashboard/tax/`,
    MANAGE_TAX_BATCH: id => `${BASE_URL}/partner/dashboard/tax/${id}`,
    UPLOAD_EXCEL_MONTHLY_TAX_DEDUCTION: id => `${BASE_URL}/partner/dashboard/tax/monthly/${id}/upload`,
    UPLOAD_EXCEL_NEW_MONTHLY_TAX_DEDUCTION: id => `${BASE_URL}/partner/dashboard/tax/new-monthly/${id}/upload`,
    UPLOAD_EXCEL_YEARLY_TAX_DEDUCTION: id => `${BASE_URL}/partner/dashboard/tax/yearly/${id}/upload`,
    UPLOAD_PDF_TAX_DEDUCTION: id => `${BASE_URL}/partner/dashboard/tax/${id}/tax-proof`,
    ADD_MITRA_TAX_DEDUCTION: (batch_id, is_monthly) => `${BASE_URL}/partner/dashboard/tax/${batch_id}/add-mitra?is_monthly=${is_monthly}`,
    TAX_BATCH_ERROR_LOGS: id => `${BASE_URL}/partner/dashboard/tax/${id}/logs`,
    MANAGE_MITRA_TAX: (batch_id, tax_id, is_monthly) => `${BASE_URL}/partner/dashboard/tax/${batch_id}/tax/${tax_id}?is_monthly=${is_monthly}`,
    DOWNLOAD_TAX_PROOF: (batch_id, tax_id) => `${BASE_URL}/partner/dashboard/tax/${batch_id}/tax/${tax_id}/download`,
    DISTRIBUTE_TAX_BATCH: (batch_id) => `${BASE_URL}/partner/dashboard/tax/${batch_id}/distribute`,
    DISTRIBUTE_TAX_PROOF: (batch_id, tax_id) => `${BASE_URL}/partner/dashboard/tax/${batch_id}/tax/${tax_id}/distribute`,
    TAX_REPORT_EXCEL: id => `${BASE_URL}/partner/dashboard/tax/${id}/report`,
    DELETE_TAX_PROOF_BY_USERID: (batch_id, user_id) => `${BASE_URL}/partner/dashboard/tax/${batch_id}/user/${user_id}`,
    DISTRIBUTE_TAX_PROOF_BY_USERID: (batch_id, user_id) => `${BASE_URL}/partner/dashboard/tax/${batch_id}/user/${user_id}/distribute`,
};

// KYC
export const KYC = {
    GET_KYC_MITRA: `${BASE_URL}/partner/dashboard/kyc/mitras`,
    GET_DETAIL_KYC_MITRA: id => `${BASE_URL}/partner/dashboard/kyc/mitras/${id}`,
    VALIDATE_KYC_DATA_SILVER: id => `${BASE_URL}/partner/dashboard/kyc/mitras/${id}/dataset/silver`,
    VALIDATE_KYC_DATA_GOLD: id => `${BASE_URL}/partner/dashboard/kyc/mitras/${id}/dataset/gold`,
    VALIDATE_KYC_DATA_PLATINUM: id => `${BASE_URL}/partner/dashboard/kyc/mitras/${id}/dataset/platinum`,
    MARK_MITRA_LABEL: `${BASE_URL}/partner/dashboard/kyc/mitra-label`,
};

// Platform version
export const PLATFORM_VERSION = {
    LATEST_VERSION: `${BASE_URL}/platform-version/latest-version`
};

// Admin Job Vacancy
export const ADMIN_JOB_VACANCY = {
    LIST: `${BASE_URL_V4}/partner/admin-job-vacancy`,
    SEARCH: `${BASE_URL_V4}/partner/admin-job-vacancy/search`,
};

// Additional Question Template Category
export const ADDITIONAL_QUESTION_TEMPLATE_CATEGORY = {
    LIST: `${BASE_URL_V4}/partner/dashboard/additional-question-template-category/category-list`
};

export const ADDITIONAL_QUESTION_TEMPLATE = {
    LIST: (categoryId) => `${BASE_URL_V4}/partner/dashboard/additional-question-template/${categoryId}/template-list`
};

export const OVERTIME_REPORT = {
    DASHBOARD: `${BASE_URL_V4}/partner/dashboard/overtime-report/recap`,
    LIST: `${BASE_URL_V4}/partner/dashboard/overtime-report`,
    VERIFY_REJECT: `${BASE_URL_V4}/partner/dashboard/overtime-report/verify`,
    DETAIL_MITRA: (job_partner_id) => `${BASE_URL_V4}/partner/dashboard/overtime-report/report/${job_partner_id}`,
    OFFICE_ROLE_OPTIONS: `${BASE_URL_V4}/partner/dashboard/overtime-report/office-role-options`,
};

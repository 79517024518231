<template>
    <div class="uk-container uk-container-expand uk-padding-small card-scrollable main">
        <button class="uk-button uk-button-default uk-margin-bottom" @click="goBack">
            <span class="uk-margin-small-right" uk-icon="icon: arrow-left"></span>
            <span>Back</span>
        </button>

        <LoaderPage v-if="isLoading" />
        <template v-else>
            <!-- Button tab -->
            <div v-if="$can('READ', 'worker-payslip')" class="uk-grid-small" uk-grid>
                <div class="uk-width-1-4">
                    <button
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                        :class="[activeTab === 'payslip_template' ? 'myrobin-background-primary myrobin-color-white' : 'myrobin-background-white myrobin-border-grey myrobin-color-grey']"
                        :disabled="activeTab === 'payslip_template'"
                        @click="setActiveTabMenu('payslip_template')"
                    >Payslip Template</button>
                </div>
                <div class="uk-width-1-4">
                    <button
                        type="button"
                        class="uk-button uk-button-default uk-border-rounded uk-width-1-1"
                        :class="[activeTab === 'detail_payslip_mitra' ? 'myrobin-background-primary myrobin-color-white' : 'myrobin-background-white myrobin-border-grey myrobin-color-grey']"
                        :disabled="activeTab === 'detail_payslip_mitra'"
                        @click="setActiveTabMenu('detail_payslip_mitra')"
                    >Detail Payslip Mitra</button>
                </div>
            </div>
            <!-- End button tab -->

            <!-- Tab content -->
            <div v-if="$can('READ', 'worker-payslip')" class="uk-width-1-1 uk-margin-top">
                <PayslipTemplate
                    v-if="activeTab === 'payslip_template'"
                    :table-loading="tableLoading"
                    :download-payslip-batch-template-loading="downloadPayslipBatchTemplateLoading"
                    :is-empty-field="isEmptyField"
                    :category-list="categoryList"
                    :is-dynamic-warning-per-record="payslipBatchIsDynamicWarningPerRecord"
                    @add-field="showAddOrEditFieldModalBox"
                    @edit-field="showAddOrEditFieldModalBox"
                    @delete-field="deleteField"
                    @set-as-highlight="setAsHighlight"
                    @remove-field-as-highlight="removeFieldAsHighlight"
                    @copy-field-template="showCopyFieldTemplateModalBox"
                    @download-payslip-batch-template="doDownloadPayslipBatchTemplate"
                    @edit-warning-letter="showEditWarningLetterModalBox"
                />
                <DetailPayslipMitra
                    v-if="activeTab === 'detail_payslip_mitra'"
                    :upload-template-loading="uploadTemplateLoading"
                    :table-loading="tableLoading"
                    :download-report-payslip-batch-excel-button-loading="downloadReportPayslipBatchExcelButtonLoading"
                    :payslips="payslips"
                    @upload-template="uploadTemplate"
                    @download-report-payslip-batch-excel="downloadReportPayslipBatchExcel"
                    @filter-payslip-batch-detail="filterPayslipBatchDetail"
                    @show-logs="showLogsModalBox"
                    @add-worker="showAddWorkerModalBox"
                    @change-limit="changeLimit"
                    @change-page="changePage"
                    @delete-worker-payslip="deleteWorkerPayslip"
                    @edit-worker-payslip="showEditWorkerPayslipModalBox"
                    @preview-worker-payslip="previewWorkerPayslip"
                    @distribute-worker-payslip="onDistributeWorkerPayslip"
                />
            </div>
            <!-- End tab content -->

            <!-- Handler when user can't access -->
            <div v-else>
                <div class="uk-flex uk-flex-center uk-flex-middle uk-height-viewport">
                    <div class="uk-flex uk-flex-center uk-flex-middle uk-width-1-1">
                        <span class="uk-text-large uk-text-bold">
                            <p>You are not authorized for read data this page</p>
                        </span>
                    </div>
                </div>
            </div>
            <!-- End handler when user can't access -->

            <!-- Add or edit field modal box -->
            <div id="add-or-edit-field-modal-box" class="add-or-edit-field-modal-box uk-flex-top" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close @click="hideAddOrEditFieldModalBox"></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">{{ actionTypeModalBox === 'add-field' ? 'Add Field' : 'Edit Field' }}</h2>
                    </div>
                    <form data-vv-scope="formField" @submit.prevent="doEditPayslipBatchTemplate">
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <label for="fieldType" class="uk-form-label">Type <span class="uk-text-danger">*</span></label>
                                    <select
                                        id="fieldType"
                                        name="fieldType"
                                        class="uk-select uk-border-rounded"
                                        v-model="formField.type"
                                        v-validate="{ required: true }"
                                        disabled
                                    >
                                        <option :value="null" selected>Select type</option>
                                        <option
                                            v-for="(type, index) in typeList"
                                            :key="index"
                                            :value="type.type_value"
                                        >{{ type.type_label || '-' }}</option>
                                    </select>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has(`formField.fieldType`)">
                                        {{ errors.first(`formField.fieldType`) }}
                                    </span>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="fieldName" class="uk-form-label">Field name <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="fieldName"
                                        name="fieldName"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        v-model="formField.name"
                                        v-validate="{ required: true }"
                                    />
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has(`formField.fieldName`)">
                                        {{ errors.first(`formField.fieldName`) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer">
                            <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                            <LoaderButton v-if="addOrEditFieldButtonLoading" />
                            <template v-else>
                                <button
                                    type="button"
                                    class="uk-button uk-button-default myrobin-border-blue myrobin-color-blue"
                                    @click="hideAddOrEditFieldModalBox"
                                >Cancel</button>
                                <button
                                    type="submit"
                                    class="uk-button"
                                    :class="[disabledButtonAddOrEditField ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledButtonAddOrEditField"
                                >{{ actionTypeModalBox === 'add-field' ? 'Create' : 'Save' }}</button>
                            </template>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End add or edit field modal box -->

            <!-- Confirmation modal box -->
            <div id="confirmation-modal-box" class="confirmation-modal-box uk-flex-top" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close @click="hideConfirmationModalBox"></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">{{ confirmationModalBoxData.title || '-' }}</h2>
                    </div>
                    <div class="uk-modal-body">
                        <p>{{ confirmationModalBoxData.message || '-' }}</p>
                    </div>
                    <div class="uk-modal-footer">
                        <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                            <LoaderButton v-if="confirmationModalBoxButtonLoading" />
                            <template v-else>
                                <button
                                    type="button"
                                    class="uk-button uk-button-default myrobin-border-blue myrobin-color-blue"
                                    @click="hideConfirmationModalBox"
                                >No</button>
                                <button
                                    type="button"
                                    class="uk-button uk-button-primary"
                                    @click="doConfirmation"
                                >Yes</button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End confirmation modal box -->

            <!-- Copy template field modal box -->
            <div id="copy-template-field-modal-box" class="copy-template-field-modal-box uk-flex-top" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button class="uk-modal-close-default" type="button" uk-close @click="hideCopyTemplateFieldModalBox"></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">Copy Template Field</h2>
                    </div>
                    <form data-vv-scope="formCopyTemplateField" @submit.prevent="doCopyTemplateField">
                        <div class="uk-modal-body">
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <p>Please select the template from which the fields will be copied.</p>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="templateName" class="uk-form-label">Template Name <span class="uk-text-danger">*</span></label>
                                    <select
                                        id="templateName"
                                        name="templateName"
                                        class="uk-select uk-border-rounded"
                                        v-model="formCopyTemplateField.copied_batch_id"
                                        v-validate="{ required: true }"
                                    >
                                        <option :value="null">Select template</option>
                                        <option
                                            v-for="(template, index) in batchOptions"
                                            :key="index"
                                            :value="template && template.value || ''"
                                        >{{ template && template.label || '-' }}</option>
                                    </select>
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has(`formCopyTemplateField.templateName`)">
                                        {{ errors.first(`formCopyTemplateField.templateName`) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-modal-footer">
                            <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                            <LoaderButton v-if="copyTemplateFieldButtonLoading" />
                            <template v-else>
                                <button
                                    type="button"
                                    class="uk-button uk-button-default myrobin-border-blue myrobin-color-blue"
                                    @click="hideCopyTemplateFieldModalBox"
                                >Cancel</button>
                                <button
                                    type="submit"
                                    class="uk-button"
                                    :class="[disabledButtonCopyTemplateField ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledButtonCopyTemplateField"
                                >Confirm</button>
                            </template>
                        </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End copy template field modal box -->

            <!-- Upload payslip modal box -->
            <div id="upload-payslip-loader-modal-box" class="upload-payslip-loader-modal-box uk-flex-top" esc-close="false" bg-close="false" uk-modal>
                <div class="uk-modal-dialog uk-margin-auto-vertical uk-width-1-4@s">
                    <div class="uk-modal-body uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                        <div class="loader"></div>
                        <p>Uploading...</p>
                    </div>
                </div>
            </div>
            <!-- End upload payslip modal box -->

            <!-- Failed to upload payslip modal box -->
            <div id="failed-to-upload-template-modal-box" class="failed-to-upload-template-modal-box uk-flex-top uk-modal-container" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default"
                        uk-close
                        @click="hideFailedToUploadModalBox"
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">Payslip Error Log</h2>
                    </div>
                    <div class="uk-modal-body ">
                        <div class="uk-flex uk-flex-middle uk-flex-between">
                            <p class="uk-text-lead uk-margin-remove">Incorrect data</p>
                            <template>
                                <button
                                    v-if="downloadRejectedDataButtonLoading"
                                    type="button"
                                    class="uk-button uk-button-default uk-border-rounded uk-margin-small-left uk-width-1-5"
                                    disabled
                                >
                                    <span><div uk-spinner="ratio: 0.5"></div></span>
                                    <span class="uk-margin-small-left">Loading</span>
                                </button>
                                <button
                                    v-else
                                    type="button"
                                    class="uk-button uk-border-rounded uk-button-primary uk-margin-small-left uk-width-1-5 uk-position-relative"
                                    @click="downloadRejectedData"
                                >
                                    <img
                                        class="uk-position-center-left uk-margin-small-left"
                                        :src="`${images}/download-outline-white.svg`"
                                        alt="Download icon"
                                    >
                                    <span>Download</span>
                                </button>
                            </template>
                        </div>
                        <div class="uk-overflow-auto uk-margin-small-top">
                            <table class="uk-table uk-table-medium uk-table-striped uk-table-middle">
                                <thead>
                                    <tr>
                                        <th class="uk-text-nowrap">User ID</th>
                                        <th class="uk-text-nowrap">Employee ID</th>
                                        <th class="uk-text-nowrap">Nama</th>
                                        <th class="uk-text-nowrap">Email</th>
                                        <th class="uk-text-nowrap">No Telp</th>
                                        <th class="uk-text-nowrap">NIK</th>
                                        <th class="uk-text-nowrap">Office</th>
                                        <th class="uk-text-nowrap">Role</th>
                                    </tr>
                                </thead>
                                <tbody v-if="rejectedData.length > 0">
                                    <tr v-for="(payslip, index) in rejectedData" :key="index">
                                        <td class="uk-text-nowrap">{{ payslip["User ID"] || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ payslip["Employee ID"] || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ payslip["Nama"] || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ payslip["Email"] || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ payslip["No Telp"] }}</td>
                                        <td class="uk-text-nowrap">{{ payslip["NIK"] || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ payslip["Office"] || '-' }}</td>
                                        <td class="uk-text-nowrap">{{ payslip["Role"] || '-' }}</td>
                                    </tr>
                                </tbody>
                                <empty-table
                                    v-else
                                    :colspan="8"
                                ></empty-table>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End failed to upload payslip modal box -->

            <!-- Add worker modal box -->
            <div id="add-worker-modal-box" class="add-worker-modal-box uk-flex-top uk-modal-container" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default"
                        uk-close
                        @click="hideAddWorkerModalBox"
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">Add Mitra</h2>
                    </div>
                    <form data-vv-scope="formAddWorker" @submit.prevent="doAddWorker">
                        <div class="uk-modal-body">
                            <!-- Worker information -->
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <p class="uk-text-left uk-text-bold uk-text-uppercase">Informasi Mitra</p>
                                </div>
                                <div class="uk-width-1-2">
                                    <label for="officeName" class="uk-form-label">Office <span class="uk-text-danger">*</span></label>
                                    <Multiselect
                                        id="officeName"
                                        name="officeName"
                                        :searchable="true"
                                        :allow-empty="true"
                                        :multiple="false"
                                        :options="officeOptions"
                                        open-direction="bottom"
                                        placeholder="Choose Office"
                                        v-model="formAddWorker.office_name"
                                        v-validate="{ required: true }"
                                    />
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.officeName')">
                                        {{ errors.first('formAddWorker.officeName') }}
                                    </span>
                                </div>
                                <div class="uk-width-1-2">
                                    <label for="employeeId" class="uk-form-label">Employee ID <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="employeeId"
                                        name="employeeId"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        v-model="formAddWorker.employee_id"
                                        v-validate="{ required: true }"
                                    />
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.employeeId')">
                                        {{ errors.first('formAddWorker.employeeId') }}
                                    </span>
                                </div>
                                <div class="uk-width-1-2">
                                    <label for="roleName" class="uk-form-label">Role <span class="uk-text-danger">*</span></label>
                                    <Multiselect
                                        id="roleName"
                                        name="roleName"
                                        :searchable="true"
                                        :allow-empty="true"
                                        :multiple="false"
                                        :options="roleOptions"
                                        open-direction="bottom"
                                        placeholder="Choose Role"
                                        v-model="formAddWorker.role_name"
                                        v-validate="{ required: true }"
                                    />
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.roleName')">
                                        {{ errors.first('formAddWorker.roleName') }}
                                    </span>
                                </div>
                                <div class="uk-width-1-2">
                                    <label for="fullname" class="uk-form-label">Nama <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="fullname"
                                        name="fullname"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        v-model="formAddWorker.fullname"
                                        v-validate="{ required: true }"
                                    />
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.fullname')">
                                        {{ errors.first('formAddWorker.fullname') }}
                                    </span>
                                </div>
                                <div class="uk-width-1-2">
                                    <label for="identityNumber" class="uk-form-label">NIK <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="identityNumber"
                                        name="identityNumber"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        v-model="formAddWorker.identity_number"
                                        v-validate="{
                                            required: true,
                                            numeric: true,
                                            length: 16
                                        }"
                                    />
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.identityNumber')">
                                        {{ errors.first('formAddWorker.identityNumber') }}
                                    </span>
                                </div>
                                <div class="uk-width-1-2">
                                    <label for="email" class="uk-form-label">Email <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        class="uk-input uk-border-rounded"
                                        v-model="formAddWorker.email"
                                        v-validate="{
                                            required: true,
                                            email: true
                                        }"
                                    />
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.email')">
                                        {{ errors.first('formAddWorker.email') }}
                                    </span>
                                </div>
                                <div class="uk-width-1-2">
                                    <label for="phoneNumber" class="uk-form-label">No Telp <span class="uk-text-danger">*</span></label>
                                    <input
                                        id="phoneNumber"
                                        name="phoneNumber"
                                        type="text"
                                        class="uk-input uk-border-rounded"
                                        v-model="formAddWorker.phone_number"
                                        v-validate="{
                                            required: true,
                                            regex: /^[+]?[(]?[0-9]{4}[)]?[-\s.]?[0-9]{4}[-\s.]?[0-9]{3,6}$/im
                                        }"
                                    />
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has('formAddWorker.phoneNumber')">
                                        {{ errors.first('formAddWorker.phoneNumber') }}
                                    </span>
                                </div>
                            </div>
                            <!-- End worker information -->

                            <!-- Divider -->
                            <hr
                                v-if="
                                    informationList.length > 0 ||
                                    incomeList.length > 0 ||
                                    deductionList.length > 0 ||
                                    taxDeductionList.length > 0 ||
                                    totalDayList.length > 0 ||
                                    totalList.length > 0
                                "
                            />
                            <!-- End divider -->

                            <!-- Information -->
                            <div v-if="informationList.length > 0" class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <p class="uk-text-left uk-text-bold uk-text-uppercase">Informasi</p>
                                </div>
                                <div v-for="(item, index) in informationList" :key="index" class="uk-width-1-1">
                                    <template v-if="item.field_name !== 'highlight'">
                                        <label :for="item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                        <input
                                            :id="item && item.field_name"
                                            :name="item && item.field_name"
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            v-model="formAddWorker[item.type_value][item.field_name]"
                                            v-validate="{ required: false }"
                                        />
                                        <span class="uk-text-small uk-text-danger" v-show="errors.has(`formAddWorker.${item && item.field_name}`)">
                                            {{ errors.first(`formAddWorker.${item && item.field_name}`) }}
                                        </span>
                                    </template>
                                </div>
                            </div>
                            <!-- End information -->

                            <!-- Divider -->
                            <hr v-if="informationList.length > 0" />
                            <!-- End divider -->

                            <!-- Income, deduction, and tax deduction -->
                            <div
                                v-if="
                                    incomeList.length > 0 ||
                                    deductionList.length > 0 ||
                                    taxDeductionList.length > 0
                                "
                                class="uk-grid-small"
                                uk-grid
                            >
                                <div
                                    v-if="incomeList.length > 0"
                                    :class="[deductionList.length > 0 || taxDeductionList.length > 0 ? 'uk-width-1-2' : 'uk-width-1-1']"
                                >
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-1">
                                            <p class="uk-text-left uk-text-bold uk-text-uppercase">Pendapatan</p>
                                        </div>
                                        <div v-for="(item, index) in incomeList" :key="index" class="uk-width-1-1">
                                            <template v-if="item && item.field_name !== 'highlight'">
                                                <label :for="item && item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                                <div v-if="item.field_name !== `Notes ${item.type_label}`" class="uk-inline uk-width-1-1">
                                                    <span class="uk-form-icon">Rp</span>
                                                    <input
                                                        :id="item && item.field_name"
                                                        :name="item && item.field_name"
                                                        type="number"
                                                        class="uk-input uk-border-rounded"
                                                        v-model="formAddWorker[item.type_value][item.field_name]"
                                                        v-validate="{ required: false }"
                                                    >
                                                </div>
                                                <input
                                                    v-else
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="text"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formAddWorker[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                />
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has(`formAddWorker.${item && item.field_name}`)">
                                                    {{ errors.first(`formAddWorker.${item && item.field_name}`) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="deductionList.length > 0"
                                    :class="[incomeList.length > 0 || taxDeductionList.length > 0 ? 'uk-width-1-2' : 'uk-width-1-1']"
                                >
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-1">
                                            <p class="uk-text-left uk-text-bold uk-text-uppercase">Potongan</p>
                                        </div>
                                        <div v-for="(item, index) in deductionList" :key="index" class="uk-width-1-1">
                                            <template v-if="item && item.field_name !== 'highlight'">
                                                <label :for="item && item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                                <div v-if="item.field_name !== `Notes ${item.type_label}`" class="uk-inline uk-width-1-1">
                                                    <span class="uk-form-icon">Rp</span>
                                                    <input
                                                        :id="item && item.field_name"
                                                        :name="item && item.field_name"
                                                        type="number"
                                                        class="uk-input uk-border-rounded"
                                                        v-model="formAddWorker[item.type_value][item.field_name]"
                                                        v-validate="{ required: false }"
                                                    >
                                                </div>
                                                <input
                                                    v-else
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="text"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formAddWorker[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                />
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has(`formAddWorker.${item && item.field_name}`)">
                                                    {{ errors.first(`formAddWorker.${item && item.field_name}`) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="taxDeductionList.length > 0"
                                    :class="[incomeList.length > 0 || deductionList.length > 0 ? 'uk-width-1-2' : 'uk-width-1-1']"
                                >
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-1">
                                            <p class="uk-text-left uk-text-bold uk-text-uppercase">Potongan Pajak</p>
                                        </div>
                                        <div v-for="(item, index) in taxDeductionList" :key="index" class="uk-width-1-1">
                                            <template v-if="item && item.field_name !== 'highlight'">
                                                <label :for="item && item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                                <div v-if="item.field_name !== `Notes ${item.type_label}`" class="uk-inline uk-width-1-1">
                                                    <span class="uk-form-icon">Rp</span>
                                                    <input
                                                        :id="item && item.field_name"
                                                        :name="item && item.field_name"
                                                        type="number"
                                                        class="uk-input uk-border-rounded"
                                                        v-model="formAddWorker[item.type_value][item.field_name]"
                                                        v-validate="{ required: false }"
                                                    >
                                                </div>
                                                <input
                                                    v-else
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="text"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formAddWorker[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                />
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has(`formAddWorker.${item && item.field_name}`)">
                                                    {{ errors.first(`formAddWorker.${item && item.field_name}`) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End income, deduction, and tax deduction -->

                            <!-- Divider -->
                            <hr
                                v-if="
                                    incomeList.length > 0 ||
                                    deductionList.length > 0 ||
                                    taxDeductionList.length > 0
                                "
                            />
                            <!-- End divider -->

                            <!-- Total days and total -->
                            <div v-if="totalDayList.length > 0 || totalList.length > 0" class="uk-grid-small" uk-grid>
                                <div
                                    v-if="totalDayList.length > 0"
                                    :class="[totalList.length > 0 ? 'uk-width-1-2' : 'uk-width-1-1']"
                                >
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-1">
                                            <p class="uk-text-left uk-text-bold uk-text-uppercase">Jumlah</p>
                                        </div>
                                        <div v-for="(item, index) in totalDayList" :key="index" class="uk-width-1-1">
                                            <template v-if="item && item.field_name !== 'highlight'">
                                                <label :for="item && item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                                <input
                                                    v-if="item.field_name !== `Notes ${item.type_label}`"
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="number"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formAddWorker[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                >
                                                <input
                                                    v-else
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="text"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formAddWorker[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                />
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has(`formAddWorker.${item && item.field_name}`)">
                                                    {{ errors.first(`formAddWorker.${item && item.field_name}`) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="totalList.length > 0"
                                    :class="[totalDayList.length > 0 ? 'uk-width-1-2' : 'uk-width-1-1']"
                                >
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-1">
                                            <p class="uk-text-left uk-text-bold uk-text-uppercase">Total</p>
                                        </div>
                                        <div v-for="(item, index) in totalList" :key="index" class="uk-width-1-1">
                                            <template v-if="item && item.field_name !== 'highlight'">
                                                <label :for="item && item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                                <div v-if="item.field_name !== `Notes ${item.type_label}`" class="uk-inline uk-width-1-1">
                                                    <span class="uk-form-icon">Rp</span>
                                                    <input
                                                        :id="item && item.field_name"
                                                        :name="item && item.field_name"
                                                        type="number"
                                                        class="uk-input uk-border-rounded"
                                                        v-model="formAddWorker[item.type_value][item.field_name]"
                                                        v-validate="{ required: false }"
                                                    >
                                                </div>
                                                <input
                                                    v-else
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="text"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formAddWorker[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                />
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has(`formAddWorker.${item && item.field_name}`)">
                                                    {{ errors.first(`formAddWorker.${item && item.field_name}`) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End total days and total -->

                            <!-- Divider -->
                            <hr
                                v-if="incomeList.length > 0 ||
                                    deductionList.length > 0 ||
                                    taxDeductionList.length > 0 &&
                                    payslipBatchIsDynamicWarningPerRecord"
                            />
                            <!-- End divider -->

                            <!-- Warning Letter -->
                            <div v-if="payslipBatchIsDynamicWarningPerRecord" class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <p class="uk-text-left uk-text-bold uk-text-uppercase">Warning Letter</p>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="warning_letter" class="uk-form-label line-clamp-1">Warning Letter</label>
                                    <vue-editor
                                        id="warning_letter"
                                        v-model="formAddWorker.warning_letter"
                                        name="warning_letter"
                                        v-validate="'required'"
                                        :editor-toolbar="['bold','italic','underline']"
                                    />
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has(`formAddWorker.warning_letter`)">
                                        {{ errors.first(`formAddWorker.warning_letter`) }}
                                    </span>
                                </div>
                            </div>
                            <!-- End Warning Letter -->
                        </div>
                        <div class="uk-modal-footer">
                            <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                                <LoaderButton v-if="addWorkerButtonLoading" />
                                <button
                                    v-else
                                    type="submit"
                                    class="uk-button"
                                    :class="[disabledButtonAddMitra ? 'uk-button-default' : 'uk-button-primary']"
                                    :disabled="disabledButtonAddMitra"
                                >Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End add worker modal box -->

            <!-- Edit worker payslip modal box -->
            <div id="edit-worker-payslip-modal-box" class="edit-worker-payslip-modal-box uk-flex-top uk-modal-container" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default"
                        uk-close
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title">Edit Payslip</h2>
                    </div>
                    <form data-vv-scope="formEditWorkerPayslip" @submit.prevent="doEditWorkerPayslip">
                        <div class="uk-modal-body">
                            <!-- Information -->
                            <div v-if="selectedWorkerPayslipInformationList.length > 0" class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <p class="uk-text-left uk-text-bold uk-text-uppercase">Informasi</p>
                                </div>
                                <div v-for="(item, index) in selectedWorkerPayslipInformationList" :key="index" class="uk-width-1-1">
                                    <template v-if="item.field_name !== 'highlight'">
                                        <label :for="item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                        <input
                                            :id="item && item.field_name"
                                            :name="item && item.field_name"
                                            type="text"
                                            class="uk-input uk-border-rounded"
                                            v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                            v-validate="{ required: false }"
                                        />
                                        <span class="uk-text-small uk-text-danger" v-show="errors.has(`formEditWorkerPayslip.${item && item.field_name}`)">
                                            {{ errors.first(`formEditWorkerPayslip.${item && item.field_name}`) }}
                                        </span>
                                    </template>
                                </div>
                            </div>
                            <!-- End information -->

                            <!-- Divider -->
                            <hr v-if="selectedWorkerPayslipInformationList.length > 0" />
                            <!-- End divider -->

                            <!-- Income, deduction, and tax deduction -->
                            <div
                                v-if="
                                    selectedWorkerPayslipIncomeList.length > 0 ||
                                    selectedWorkerPayslipDeductionList.length > 0 ||
                                    selectedWorkerPayslipTaxDeductionList.length > 0
                                "
                                class="uk-grid-small"
                                uk-grid
                            >
                                <div
                                    v-if="selectedWorkerPayslipIncomeList.length > 0"
                                    :class="[selectedWorkerPayslipDeductionList.length > 0 || selectedWorkerPayslipTaxDeductionList.length > 0 ? 'uk-width-1-2' : 'uk-width-1-1']"
                                >
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-1">
                                            <p class="uk-text-left uk-text-bold uk-text-uppercase">Pendapatan</p>
                                        </div>
                                        <div v-for="(item, index) in selectedWorkerPayslipIncomeList" :key="index" class="uk-width-1-1">
                                            <template v-if="item && item.field_name !== 'highlight'">
                                                <label :for="item && item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                                <div v-if="item.field_name !== `Notes ${item.type_label}`" class="uk-inline uk-width-1-1">
                                                    <span class="uk-form-icon">Rp</span>
                                                    <input
                                                        :id="item && item.field_name"
                                                        :name="item && item.field_name"
                                                        type="number"
                                                        class="uk-input uk-border-rounded"
                                                        v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                                        v-validate="{ required: false }"
                                                    >
                                                </div>
                                                <input
                                                    v-else
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="text"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                />
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has(`formEditWorkerPayslip.${item && item.field_name}`)">
                                                    {{ errors.first(`formEditWorkerPayslip.${item && item.field_name}`) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="selectedWorkerPayslipDeductionList.length > 0"
                                    :class="[selectedWorkerPayslipIncomeList.length > 0 || selectedWorkerPayslipTaxDeductionList.length > 0 ? 'uk-width-1-2' : 'uk-width-1-1']"
                                >
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-1">
                                            <p class="uk-text-left uk-text-bold uk-text-uppercase">Potongan</p>
                                        </div>
                                        <div v-for="(item, index) in selectedWorkerPayslipDeductionList" :key="index" class="uk-width-1-1">
                                            <template v-if="item && item.field_name !== 'highlight'">
                                                <label :for="item && item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                                <div v-if="item.field_name !== `Notes ${item.type_label}`" class="uk-inline uk-width-1-1">
                                                    <span class="uk-form-icon">Rp</span>
                                                    <input
                                                        :id="item && item.field_name"
                                                        :name="item && item.field_name"
                                                        type="number"
                                                        class="uk-input uk-border-rounded"
                                                        v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                                        v-validate="{ required: false }"
                                                    >
                                                </div>
                                                <input
                                                    v-else
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="text"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                />
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has(`formEditWorkerPayslip.${item && item.field_name}`)">
                                                    {{ errors.first(`formEditWorkerPayslip.${item && item.field_name}`) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="selectedWorkerPayslipTaxDeductionList.length > 0"
                                    :class="[selectedWorkerPayslipIncomeList.length > 0 || selectedWorkerPayslipDeductionList.length > 0 ? 'uk-width-1-2' : 'uk-width-1-1']"
                                >
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-1">
                                            <p class="uk-text-left uk-text-bold uk-text-uppercase">Potongan Pajak</p>
                                        </div>
                                        <div v-for="(item, index) in selectedWorkerPayslipTaxDeductionList" :key="index" class="uk-width-1-1">
                                            <template v-if="item && item.field_name !== 'highlight'">
                                                <label :for="item && item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                                <div v-if="item.field_name !== `Notes ${item.type_label}`" class="uk-inline uk-width-1-1">
                                                    <span class="uk-form-icon">Rp</span>
                                                    <input
                                                        :id="item && item.field_name"
                                                        :name="item && item.field_name"
                                                        type="number"
                                                        class="uk-input uk-border-rounded"
                                                        v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                                        v-validate="{ required: false }"
                                                    >
                                                </div>
                                                <input
                                                    v-else
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="text"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                />
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has(`formEditWorkerPayslip.${item && item.field_name}`)">
                                                    {{ errors.first(`formEditWorkerPayslip.${item && item.field_name}`) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End income, deduction, and tax deduction -->

                            <!-- Divider -->
                            <hr
                                v-if="
                                    selectedWorkerPayslipIncomeList.length > 0 ||
                                    selectedWorkerPayslipDeductionList.length > 0 ||
                                    selectedWorkerPayslipTaxDeductionList.length > 0
                                "
                            />
                            <!-- End divider -->

                            <!-- Total days and total -->
                            <div v-if="selectedWorkerPayslipTotalDayList.length > 0 || selectedWorkerPayslipTotalList.length > 0" class="uk-grid-small" uk-grid>
                                <div
                                    v-if="selectedWorkerPayslipTotalDayList.length > 0"
                                    :class="[selectedWorkerPayslipTotalList.length > 0 ? 'uk-width-1-2' : 'uk-width-1-1']"
                                >
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-1">
                                            <p class="uk-text-left uk-text-bold uk-text-uppercase">Jumlah</p>
                                        </div>
                                        <div v-for="(item, index) in selectedWorkerPayslipTotalDayList" :key="index" class="uk-width-1-1">
                                            <template v-if="item && item.field_name !== 'highlight'">
                                                <label :for="item && item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                                <input
                                                    v-if="item.field_name !== `Notes ${item.type_label}`"
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="number"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                >
                                                <input
                                                    v-else
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="text"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                />
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has(`formEditWorkerPayslip.${item && item.field_name}`)">
                                                    {{ errors.first(`formEditWorkerPayslip.${item && item.field_name}`) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="selectedWorkerPayslipTotalList.length > 0"
                                    :class="[selectedWorkerPayslipTotalDayList.length > 0 ? 'uk-width-1-2' : 'uk-width-1-1']"
                                >
                                    <div class="uk-grid-small" uk-grid>
                                        <div class="uk-width-1-1">
                                            <p class="uk-text-left uk-text-bold uk-text-uppercase">Total</p>
                                        </div>
                                        <div v-for="(item, index) in selectedWorkerPayslipTotalList" :key="index" class="uk-width-1-1">
                                            <template v-if="item && item.field_name !== 'highlight'">
                                                <label :for="item && item.field_name" class="uk-form-label line-clamp-1">{{ item && item.field_name || '-' }}</label>
                                                <div v-if="item.field_name !== `Notes ${item.type_label}`" class="uk-inline uk-width-1-1">
                                                    <span class="uk-form-icon">Rp</span>
                                                    <input
                                                        :id="item && item.field_name"
                                                        :name="item && item.field_name"
                                                        type="number"
                                                        class="uk-input uk-border-rounded"
                                                        v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                                        v-validate="{ required: false }"
                                                    >
                                                </div>
                                                <input
                                                    v-else
                                                    :id="item && item.field_name"
                                                    :name="item && item.field_name"
                                                    type="text"
                                                    class="uk-input uk-border-rounded"
                                                    v-model="formEditWorkerPayslip[item.type_value][item.field_name]"
                                                    v-validate="{ required: false }"
                                                />
                                                <span class="uk-text-small uk-text-danger" v-show="errors.has(`formEditWorkerPayslip.${item && item.field_name}`)">
                                                    {{ errors.first(`formEditWorkerPayslip.${item && item.field_name}`) }}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- End total days and total -->

                             <!-- Divider -->
                            <hr
                                v-if="selectedWorkerPayslipIncomeList.length > 0 ||
                                    selectedWorkerPayslipDeductionList.length > 0 ||
                                    selectedWorkerPayslipTaxDeductionList.length > 0 &&
                                    payslipBatchIsDynamicWarningPerRecord"
                            />
                            <!-- End divider -->

                            <!-- Warning Letter -->
                            <div v-if="payslipBatchIsDynamicWarningPerRecord" class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <p class="uk-text-left uk-text-bold uk-text-uppercase">Warning Letter</p>
                                </div>
                                <div class="uk-width-1-1">
                                    <label for="warning_letter" class="uk-form-label line-clamp-1">Warning Letter</label>
                                    <vue-editor
                                        id="warning_letter"
                                        v-model="formEditWorkerPayslip.warning_letter"
                                        name="warning_letter"
                                        v-validate="'required'"
                                        :editor-toolbar="['bold','italic','underline']"
                                    />
                                    <span class="uk-text-small uk-text-danger" v-show="errors.has(`formEditWorkerPayslip.warning_letter`)">
                                        {{ errors.first(`formEditWorkerPayslip.warning_letter`) }}
                                    </span>
                                </div>
                            </div>
                            <!-- End Warning Letter -->
                        </div>
                        <div class="uk-modal-footer">
                            <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                                <LoaderButton v-if="editWorkerPayslipButtonLoading" />
                                <template v-else>
                                    <button
                                        type="button"
                                        class="uk-button uk-button-default myrobin-border-blue myrobin-color-blue" 
                                        @click="hideEditWorkerPayslipModalBox"
                                    >Cancel</button>
                                    <button
                                        type="submit"
                                        class="uk-button uk-button-primary"
                                    >Save</button>
                                </template>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End add worker payslip modal box -->

            <!-- Preview detail payslip mitra modal box -->
            <div id="preview-detail-payslip-mitra-modal-box" class="preview-detail-payslip-mitra-modal-box uk-flex-top" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default"
                        uk-close
                        @click="hidePreviewWorkerPayslipModalBox"
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-margin-remove">Payslip Preview</h2>
                    </div>
                    <div class="uk-modal-body">
                        <div class="uk-grid-small" uk-grid>
                            <div class="uk-width-1-1">
                                <p class="uk-margin-remove uk-text-center uk-text-bold" style="font-size: 18px;">{{ payslipBatchName || '-' }}</p>
                                <p class="uk-margin-remove uk-text-center">{{ getMonth(payslipBatchMonth) }} {{ payslipBatchYear }}</p>
                            </div>
                            <div class="uk-width-1-1">
                                <div
                                    v-for="(worker, workerIndex) in selctedWorkerDetailList"
                                    :key="`worker-${workerIndex}`"
                                    class="uk-width-1-1 uk-column-1-2@m"
                                    style="
                                        border-radius: 10px;
                                        padding: 10px 15px;
                                        background-color: #F5F6F6;
                                        margin-bottom: 3px
                                    "
                                >
                                    <p class="uk-margin-remove uk-text-bold">{{ worker.label }}</p>
                                    <p class="uk-margin-remove">{{ worker.value }}</p>
                                </div>
                            </div>
                            <div class="uk-width-1-1" style="color: black">
                                <div class="payslip-detail uk-grid-small" uk-grid>
                                    <div v-for="(category, categoryIndex) in selectedWorkerPayslipCategoryList" :key="`category-2-${categoryIndex}`" class="uk-width-1-1 uk-margin-remove">
                                        <div v-if="category && category.categoryValue === 'information'">
                                            <p class="uk-margin-remove uk-text-left uk-text-bold">{{ category && category.categoryLabel }}</p>
                                            <div v-if="category && category.fieldList.length > 0" class="uk-margin-small-top uk-width-1-1">
                                                <div class="uk-flex uk-flex-column uk-flex-left uk-flex-top gap-5px">
                                                    <div v-for="(field, fieldIndex) in category.fieldList" :key="`field-1-${fieldIndex}`" class="uk-width-1-1">
                                                        <template v-if="field.field_value || field.field_value === 0">
                                                            <template v-if="field.field_name !== 'highlight' && field.field_name !== `Notes ${field.type_label}`">
                                                                <div class="uk-flex uk-flex-top uk-flex-between gap-10px">
                                                                    <p class="uk-margin-remove uk-text-left uk-width-1-2">{{ field.field_name }}</p>
                                                                    <p v-if="field.field_value_type === 'curency'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                                        {{ field.field_value ? getCurrency(field.field_value) : getCurrency(0) }}
                                                                    </p>
                                                                    <p v-else-if="field.field_value_type === 'number'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                                        {{ field.field_value || 0 }}
                                                                    </p>
                                                                    <p v-else class="uk-margin-remove uk-text-right uk-width-1-2">{{ field.field_value || '-' }}</p>
                                                                </div>
                                                            </template>
                                                            <div v-else-if="field.field_name === `Notes ${field.type_label}`" class="uk-flex uk-flex-top uk-flex-between gap-10px">
                                                                <p class="uk-margin-remove uk-text-left uk-width-1-2">{{ field.field_name }}</p>
                                                                <p class="uk-margin-remove uk-text-right uk-width-1-2">{{ field.field_value || '-' }}</p>
                                                            </div>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>
                                        <div
                                            v-else
                                            class="uk-width-1-1"
                                            style="
                                                border: solid 2px #F5F6F6;
                                                border-radius: 10px;
                                                margin-bottom: 15px
                                            "
                                        >
                                            <div class="uk-margin-remove" style="padding: 10px;">
                                                <p class="uk-margin-remove uk-text-left uk-text-bold myrobin-color-aqua">{{ category && category.categoryLabel }}</p>
                                                <div v-if="category && category.fieldList.length > 0" class="uk-margin-small-top uk-width-1-1">
                                                    <div class="uk-flex uk-flex-column uk-flex-left uk-flex-top gap-5px">
                                                        <div v-for="(field, fieldIndex) in category.fieldList" :key="`field-1-${fieldIndex}`" class="uk-width-1-1">
                                                            <template v-if="field.field_value || field.field_value === 0">
                                                                <template v-if="field.field_name !== 'highlight' && field.field_name !== `Notes ${field.type_label}`">
                                                                    <div class="uk-flex uk-flex-top uk-flex-between gap-10px">
                                                                        <p class="uk-margin-remove uk-text-left uk-width-1-2">{{ field.field_name }}</p>
                                                                        <p v-if="field.field_value_type === 'curency'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                                            {{ field.field_value ? getCurrency(field.field_value) : getCurrency(0) }}
                                                                        </p>
                                                                        <p v-else-if="field.field_value_type === 'number'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                                            {{ field.field_value || 0 }}
                                                                        </p>
                                                                        <p v-else class="uk-margin-remove uk-text-right uk-width-1-2">{{ field.field_value || '-' }}</p>
                                                                    </div>
                                                                </template>
                                                                <div v-else-if="field.field_name === `Notes ${field.type_label}`" class="uk-flex uk-flex-top uk-flex-between gap-10px">
                                                                    <p class="uk-margin-remove uk-text-left uk-width-1-2">{{ field.field_name }}</p>
                                                                    <p class="uk-margin-remove uk-text-right uk-width-1-2">{{ field.field_value || '-' }}</p>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else class="uk-margin-small-top uk-width-1-1">
                                                    <p class="uk-margin-remove uk-text-right">-</p>
                                                </div>
                                            </div>
                                            <div
                                                v-if="category.highlightField && category.highlightField.field_name && category.highlightField.field_value !== null"
                                                class="uk-margin-remove uk-flex uk-flex-top uk-flex-between uk-text-bold gap-10px highlight-component"
                                            >
                                                <p class="uk-margin-remove uk-text-left uk-width-1-2">{{ category.highlightField.field_name }}</p>
                                                <p v-if="category.highlightField.field_value_type === 'curency'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                    {{ category.highlightField.field_value ? getCurrency(category.highlightField.field_value) : getCurrency(0) }}
                                                </p>
                                                <p v-else-if="category.highlightField.field_value_type === 'number'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                    {{ category.highlightField.field_value || 0 }}
                                                </p>
                                                <p v-else class="uk-margin-remove uk-text-right uk-width-1-2">{{ category.highlightField.field_value || '-' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="payslip-warning-box uk-width-1-1 uk-text-center">
                                <div v-html="selectedWorkerPayslip.warning_letter" style="color: #F44336;" id="containerHtml"></div>
                            </div>
                            <div class="uk-width-1-1">
                                <div class="uk-flex uk-flex-middle uk-flex-center">
                                    <button
                                        type="button"
                                        class="uk-button uk-button-primary uk-width-1-3 uk-border-rounded uk-position-relative"
                                        @click="showDownloadWorkerPayslipModalBox"
                                    >
                                        <img class="uk-position-center-left uk-margin-small-left" :src="`${images}/download-outline.svg`" alt="Download icon">
                                        <span>Download</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-modal-footer">
                        <div class="uk-flex uk-flex-middle uk-flex-right">
                            <button 
                                type="button"
                                class="uk-button uk-button-primary"
                                @click="hidePreviewWorkerPayslipModalBox"
                            >Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End preview detail payslip mitra modal box -->

            <!-- Download detail payslip mitra modal box -->
            <div id="download-detail-payslip-mitra-modal-box" class="download-detail-payslip-mitra-modal-box uk-flex-top" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default no-print-me"
                        uk-close
                        @click="hideDownloadWorkerPayslipModalBox"
                    ></button>
                    <div class="uk-modal-body">
                        <div class="uk-flex uk-flex-column">
                            <div class="uk-width-1-1">
                                <div class="uk-flex uk-flex-middle uk-flex-center">
                                    <img src="/images/logo-inline.png" alt="Myrobin logo" class="uk-width-1-3 h-auto object-cover object-center uk-margin-small-right" />
                                    <p class="uk-margin-remove uk-text-left uk-text-bold">{{ selectedWorkerHeadingPage }}</p>
                                </div>
                            </div>
                            <div class="uk-grid-small" uk-grid>
                                <div class="uk-width-1-1">
                                    <p class="uk-margin-remove uk-text-center uk-text-bold" style="font-size: 18px;">{{ payslipBatchName || '-' }}</p>
                                    <p class="uk-margin-remove uk-text-center">{{ getMonth(payslipBatchMonth) }} {{ payslipBatchYear }}</p>
                                </div>
                                <div class="uk-width-1-1">
                                    <div class="worker-detail">
                                        <div
                                            v-for="(worker, workerIndex) in selctedWorkerDetailList"
                                            :key="`worker-${workerIndex}`"
                                            class="uk-width-1-1 worker-detail-card-item uk-flex uk-flex-middle uk-flex-start gap-10px"
                                        >
                                            <p class="uk-margin-remove uk-text-left uk-text-bold uk-width-1-3">{{ worker.label || '-' }}</p>
                                            <p class="uk-margin-remove uk-text-left uk-width-auto">{{ worker.value || '-' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="uk-width-1-1" style="color: black">
                                    <div class="payslip-detail uk-grid-small" uk-grid>
                                        <div v-for="(category, categoryIndex) in selectedWorkerPayslipCategoryList" :key="`category-2-${categoryIndex}`" class="uk-width-1-1 uk-margin-remove">
                                            <div v-if="category && category.categoryValue === 'information'">
                                                <p class="uk-margin-remove uk-text-left uk-text-bold">{{ category && category.categoryLabel }}</p>
                                                <div v-if="category && category.fieldList.length > 0" class="uk-margin-small-top uk-width-1-1">
                                                    <div class="uk-flex uk-flex-column uk-flex-left uk-flex-top gap-5px">
                                                        <div v-for="(field, fieldIndex) in category.fieldList" :key="`field-1-${fieldIndex}`" class="uk-width-1-1">
                                                            <template v-if="field.field_value || field.field_value === 0">
                                                                <template v-if="field.field_name !== 'highlight' && field.field_name !== `Notes ${field.type_label}`">
                                                                    <div class="uk-flex uk-flex-top uk-flex-between gap-10px">
                                                                        <p class="uk-margin-remove uk-text-left uk-width-1-2">{{ field.field_name }}</p>
                                                                        <p v-if="field.field_value_type === 'curency'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                                            {{ field.field_value ? getCurrency(field.field_value) : getCurrency(0) }}
                                                                        </p>
                                                                        <p v-else-if="field.field_value_type === 'number'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                                            {{ field.field_value || 0 }}
                                                                        </p>
                                                                        <p v-else class="uk-margin-remove uk-text-right uk-width-1-2">{{ field.field_value || '-' }}</p>
                                                                    </div>
                                                                </template>
                                                                <div v-else-if="field.field_name === `Notes ${field.type_label}`" class="uk-flex uk-flex-top uk-flex-between gap-10px">
                                                                    <p class="uk-margin-remove uk-text-left uk-width-1-2">{{ field.field_name }}</p>
                                                                    <p class="uk-margin-remove uk-text-right uk-width-1-2">{{ field.field_value || '-' }}</p>
                                                                </div>
                                                            </template>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                            <div
                                                v-else
                                                class="uk-width-1-1 no-break"
                                                style="
                                                    border: solid 2px #F5F6F6;
                                                    border-radius: 10px;
                                                    margin-bottom: 15px
                                                "
                                            >
                                                <div class="uk-margin-remove" style="padding: 10px;">
                                                    <p class="uk-margin-remove uk-text-left uk-text-bold myrobin-color-aqua">{{ category && category.categoryLabel }}</p>
                                                    <div v-if="category && category.fieldList.length > 0" class="uk-margin-small-top uk-width-1-1">
                                                        <div class="uk-flex uk-flex-column uk-flex-left uk-flex-top gap-5px">
                                                            <div v-for="(field, fieldIndex) in category.fieldList" :key="`field-1-${fieldIndex}`" class="uk-width-1-1">
                                                                <template v-if="field.field_value || field.field_value === 0">
                                                                    <template v-if="field.field_name !== 'highlight' && field.field_name !== `Notes ${field.type_label}`">
                                                                        <div class="uk-flex uk-flex-top uk-flex-between gap-10px">
                                                                            <p class="uk-margin-remove uk-text-left uk-width-1-2">{{ field.field_name }}</p>
                                                                            <p v-if="field.field_value_type === 'curency'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                                                {{ field.field_value ? getCurrency(field.field_value) : getCurrency(0) }}
                                                                            </p>
                                                                            <p v-else-if="field.field_value_type === 'number'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                                                {{ field.field_value || 0 }}
                                                                            </p>
                                                                            <p v-else class="uk-margin-remove uk-text-right uk-width-1-2">{{ field.field_value || '-' }}</p>
                                                                        </div>
                                                                    </template>
                                                                    <div v-else-if="field.field_name === `Notes ${field.type_label}`" class="uk-flex uk-flex-top uk-flex-between gap-10px">
                                                                        <p class="uk-margin-remove uk-text-left uk-width-1-2">{{ field.field_name }}</p>
                                                                        <p class="uk-margin-remove uk-text-right uk-width-1-2">{{ field.field_value || '-' }}</p>
                                                                    </div>
                                                                </template>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-else class="uk-margin-small-top uk-width-1-1">
                                                        <p class="uk-margin-remove uk-text-right">-</p>
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="category.highlightField && category.highlightField.field_name && category.highlightField.field_value !== null"
                                                    class="uk-margin-remove uk-flex uk-flex-top uk-flex-between uk-text-bold gap-10px highlight-component"
                                                >
                                                    <p class="uk-margin-remove uk-text-left uk-width-1-2">{{ category.highlightField.field_name }}</p>
                                                    <p v-if="category.highlightField.field_value_type === 'curency'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                        {{ category.highlightField.field_value ? getCurrency(category.highlightField.field_value) : getCurrency(0) }}
                                                    </p>
                                                    <p v-else-if="category.highlightField.field_value_type === 'number'" class="uk-margin-remove uk-text-right uk-width-1-2">
                                                        {{ category.highlightField.field_value || 0 }}
                                                    </p>
                                                    <p v-else class="uk-margin-remove uk-text-right uk-width-1-2">{{ category.highlightField.field_value || '-' }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="payslip-warning-box uk-width-1-1 uk-text-center no-break">
                                    <div v-html="selectedWorkerPayslip.warning_letter" style="color: #F44336;" id="containerHtml"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End download detail payslip mitra modal box -->

            <!-- Edit warning letter modal box -->
            <div id="edit-warning-letter-modal-box" class="edit-warning-letter-modal-box uk-flex-top uk-modal-container" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog uk-margin-auto-vertical">
                    <button
                        type="button"
                        class="uk-modal-close-default"
                        uk-close
                        @click="hideEditWarningLetterModalBox"
                    ></button>
                    <div class="uk-modal-header">
                        <h2 class="uk-modal-title uk-margin-remove">Edit Warning Letter</h2>
                    </div>
                    <form data-vv-scope="formWarningLetter" @submit.prevent="doEditWarningLetter">
                        <div class="uk-modal-body">
                            <vue-editor
                                id="warning_letter"
                                v-model="formWarningLetter.warning_letter"
                                name="warning_letter"
                                v-validate="'required'"
                                :editor-toolbar="['bold','italic','underline']"
                            />
                            <span class="uk-text-small uk-text-danger" v-show="errors.has(`formWarningLetter.warning_letter`)">
                                {{ errors.first(`formWarningLetter.warning_letter`) }}
                            </span>
                        </div>
                        <div class="uk-modal-footer">
                            <div class="uk-flex uk-flex-middle uk-flex-right gap-10px">
                                <button 
                                    type="button"
                                    class="uk-button uk-button-default"
                                    @click="hideEditWarningLetterModalBox"
                                >Close</button>
                                <button 
                                    type="submit"
                                    class="uk-button uk-button-primary"
                                >Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <!-- End warning letter modal box -->

            <!-- Loading process modal box -->
            <div id="processing-modal" class="processing-modal" uk-modal esc-close="false" bg-close="false">
                <div class="uk-modal-dialog">
                    <div class="uk-modal-body">
                        <div class="uk-flex uk-flex-column uk-flex-middle uk-flex-center">
                            <div uk-spinner="ratio: 2"></div>
                            <div class="uk-margin-top">Please wait...</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end loading process modal box -->
        </template>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
    notificationSuccess,
    notificationDangerCustom,
    notificationDanger
} from '@/utils/notification';
import { excelDownloader } from '@/utils/helper';
import { PREFIX_IMAGE, DEFAULT_WARNING_LETTER_PAYSLIP } from "@/utils/constant";
import Multiselect from 'vue-multiselect';
import formatter from '@/utils/formatter';
import { VueEditor } from 'vue2-editor';

export default {
    name: 'PayslipDetail',
    components: {
        LoaderPage: () => import('@/components/globals/LoaderPage'),
        PayslipTemplate: () => import('@/components/globals/PayslipV3/PayslipTemplate'),
        DetailPayslipMitra: () => import('@/components/globals/PayslipV3/DetailPayslipMitra'),
        LoaderButton: () => import('@/components/globals/LoaderButton'),
        EmptyTable: () => import('@/components/globals/tables/EmptyTable'),
        Multiselect,
        VueEditor
    },
    data() {
        return {
            images: PREFIX_IMAGE,
            isLoading: false,
            activeTab: 'payslip_template',
            tableLoading: false,
            payslipBatchDetail: null,
            meta: {
                limit: 10,
                page: 1,
                id: this.$route.params.id,
                worker_name: ''
            },
            downloadPayslipBatchTemplateLoading: false,
            isEmptyField: true,
            actionTypeModalBox: null,
            typeList: [
                {
                    id: 1,
                    type_label: 'Informasi',
                    type_value: 'information'
                },
                {
                    id: 2,
                    type_label: 'Pendapatan',
                    type_value: 'income'
                },
                {
                    id: 3,
                    type_label: 'Potongan',
                    type_value: 'deduction'
                },
                {
                    id: 4,
                    type_label: 'Potongan Pajak',
                    type_value: 'tax_deduction'
                },
                {
                    id: 5,
                    type_label: 'Jumlah',
                    type_value: 'total_days'
                },
                {
                    id: 6,
                    type_label: 'Total',
                    type_value: 'total'
                },
                {
                    id: 7,
                    type_label: 'Employee ID',
                    type_value: 'employee_id'
                },
                {
                    id: 8,
                    type_label: 'Payslip / Payment Receipt',
                    type_value: 'heading'
                },
                {
                    id: 9,
                    type_label: 'Nama Pekerja',
                    type_value: 'fullname'
                },
                {
                    id: 10,
                    type_label: 'Job Role',
                    type_value: 'role_name'
                },
                {
                    id: 11,
                    type_label: 'Office',
                    type_value: 'office_name'
                }
            ],
            formWarningLetter: {
                warning_letter: null
            },
            formField: {
                type: null,
                name: null
            },
            selectedField: {
                type_label: null,
                type_value: null,
                field_name: null
            },
            addOrEditFieldButtonLoading: false,
            confirmationModalBoxData: {
                title: null,
                message: null
            },
            confirmationModalBoxButtonLoading: false,
            batchOptions: [],
            officeOptions: [],
            roleOptions: [],
            copyTemplateFieldButtonLoading: false,
            formCopyTemplateField: {
                copied_batch_id: null
            },
            uploadTemplateLoading: false,
            downloadReportPayslipBatchExcelButtonLoading: false,
            downloadRejectedDataButtonLoading: false,
            filenameExcel: null,
            rejectedData: [],
            addWorkerButtonLoading: false,
            formAddWorker: {
                employee_id: null,
                email: null,
                fullname: null,
                identity_number: null,
                phone_number: null,
                office_name: null,
                role_name: null,
                income: {},
                deduction: {},
                total: {},
                total_days: {},
                information: {},
                tax_deduction: {},
                warning_letter: DEFAULT_WARNING_LETTER_PAYSLIP
            },
            formEditWorkerPayslip: {
                income: {},
                deduction: {},
                total: {},
                total_days: {},
                information: {},
                tax_deduction: {},
                warning_letter: DEFAULT_WARNING_LETTER_PAYSLIP
            },
            selectedWorkerPayslip: {
                batch_id: null,
                slip_id: null,
                employee_id: null,
                fullname: null,
                office_name: null,
                role_name: null,
                income: {},
                deduction: {},
                total: {},
                total_days: {},
                information: {},
                tax_deduction: {}
            },
            editWorkerPayslipButtonLoading: false
        };
    },
    computed: {
        ...mapGetters({
            company_offices: 'company_office/company_offices',
            roles: "option_data/roles"
        }),
        payslipBatchId() {
            return this.payslipBatchDetail && this.payslipBatchDetail._id || null;
        },
        payslipBatchName() {
            return this.payslipBatchDetail && this.payslipBatchDetail.name || null;
        },
        payslipBatchMonth() {
            return this.payslipBatchDetail && this.payslipBatchDetail.month || 0;
        },
        payslipBatchYear() {
            return this.payslipBatchDetail && this.payslipBatchDetail.year || 0;
        },
        payslipBatchIsDynamicWarningPerRecord() {
            return this.payslipBatchDetail && this.payslipBatchDetail.is_dynamic_warning_per_record || false;
        },
        payslipBatchWarningLetter() {
            return this.payslipBatchDetail && this.payslipBatchDetail.warning_letter || null;
        },
        changeField() {
            return this.payslipBatchDetail && this.payslipBatchDetail.change_field || {};
        },
        information() {
            return this.payslipBatchDetail && this.payslipBatchDetail.information || {};
        },
        income() {
            return this.payslipBatchDetail && this.payslipBatchDetail.income || {};
        },
        deduction() {
            return this.payslipBatchDetail && this.payslipBatchDetail.deduction || {};
        },
        taxDeduction() {
            return this.payslipBatchDetail && this.payslipBatchDetail.tax_deduction || {};
        },
        totalDays() {
            return this.payslipBatchDetail && this.payslipBatchDetail.total_days || {};
        },
        total() {
            return this.payslipBatchDetail && this.payslipBatchDetail.total || {};
        },
        payslips() {
            return {
                docs: this.payslipBatchDetail && this.payslipBatchDetail.payslips && this.payslipBatchDetail.payslips.docs || [],
                totalDocs: this.payslipBatchDetail && this.payslipBatchDetail.payslips && this.payslipBatchDetail.payslips.totalDocs || 0,
            };
        },
        changeFieldList() {
            const data = [];

            if (
                typeof this.changeField &&
                Object.keys(this.changeField).length > 0 &&
                this.changeField.constructor === Object
            ) {                
                for (const key in this.changeField) {                    
                    data.push({
                        type_label: this.changeFieldTypeLabel(key),
                        type_value: key,
                        field_name: this.changeField[key],
                        field_value: this.changeField[key],
                        field_value_type: 'string'
                    });
                }
            }

            return data;
        },
        informationList() {
            const data = [];

            if (
                typeof this.information &&
                Object.keys(this.information).length > 0 &&
                this.information.constructor === Object
            ) {
                for (const key in this.information) {
                    data.push({
                        type_label: 'Informasi',
                        type_value: 'information',
                        field_name: key,
                        field_value: this.information[key],
                        field_value_type: 'string'
                    });
                }
            }

            return data;
        },
        incomeList() {
            const data = [];

            if (
                typeof this.income &&
                Object.keys(this.income).length > 0 &&
                this.income.constructor === Object
            ) {
                for (const key in this.income) {
                    data.push({
                        type_label: 'Pendapatan',
                        type_value: 'income',
                        field_name: key,
                        field_value: this.income[key],
                        field_value_type: 'curency'
                    });
                }
            }

            return data;
        },
        deductionList() {
            const data = [];

            if (
                typeof this.deduction &&
                Object.keys(this.deduction).length > 0 &&
                this.deduction.constructor === Object
            ) {
                for (const key in this.deduction) {
                    data.push({
                        type_label: 'Potongan',
                        type_value: 'deduction',
                        field_name: key,
                        field_value: this.deduction[key],
                        field_value_type: 'curency'
                    });
                }
            }

            return data;
        },
        taxDeductionList() {
            const data = [];

            if (
                typeof this.taxDeduction &&
                Object.keys(this.taxDeduction).length > 0 &&
                this.taxDeduction.constructor === Object
            ) {
                for (const key in this.taxDeduction) {
                    data.push({
                        type_label: 'Potongan Pajak',
                        type_value: 'tax_deduction',
                        field_name: key,
                        field_value: this.taxDeduction[key],
                        field_value_type: 'curency'
                    });
                }
            }

            return data;
        },
        totalDayList() {
            const data = [];

            if (
                typeof this.totalDays &&
                Object.keys(this.totalDays).length > 0 &&
                this.totalDays.constructor === Object
            ) {
                for (const key in this.totalDays) {
                    data.push({
                        type_label: 'Jumlah',
                        type_value: 'total_days',
                        field_name: key,
                        field_value: this.totalDays[key],
                        field_value_type: 'number'
                    });
                }
            }

            return data;
        },
        totalList() {
            const data = [];

            if (
                typeof this.total &&
                Object.keys(this.total).length > 0 &&
                this.total.constructor === Object
            ) {
                for (const key in this.total) {
                    data.push({
                        type_label: 'Total',
                        type_value: 'total',
                        field_name: key,
                        field_value: this.total[key],
                        field_value_type: 'curency'
                    });
                }
            }

            return data;
        },
        informationHighlightField() {
            return this.informationList.find((item) => item && item.field_name === 'highlight') || {};
        },
        incomeHighlightField() {
            return this.incomeList.find((item) => item && item.field_name === 'highlight') || {};
        },
        deductionHighlightField() {
            return this.deductionList.find((item) => item && item.field_name === 'highlight') || {};
        },
        taxDeductionHighlightField() {
            return this.taxDeductionList.find((item) => item && item.field_name === 'highlight') || {};
        },
        totalDaysHighlightField() {
            return this.totalDayList.find((item) => item && item.field_name === 'highlight') || {};
        },
        totalHighlightField() {
            return this.totalList.find((item) => item && item.field_name === 'highlight') || {};
        },
        informationNotesField() {
            return this.informationList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        incomeNotesField() {
            return this.incomeList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        deductionNotesField() {
            return this.deductionList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        taxDeductionNotesField() {
            return this.taxDeductionList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        totalDaysNotesField() {
            return this.totalDayList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        totalNotesField() {
            return this.totalList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        categoryList() {
            return [
                {
                    categoryLabel: 'Informasi Data Mitra',
                    categoryValue: 'worker_information',
                    fieldList: this.changeFieldList,
                    highlightField: {},
                    notesField: {}
                },
                {
                    categoryLabel: 'Informasi',
                    categoryValue: 'information',
                    fieldList: this.informationList,
                    highlightField: this.informationHighlightField,
                    notesField: this.informationNotesField
                },
                {
                    categoryLabel: 'Pendapatan',
                    categoryValue: 'income',
                    fieldList: this.incomeList,
                    highlightField: this.incomeHighlightField,
                    notesField: this.incomeNotesField
                },
                {
                    categoryLabel: 'Potongan',
                    categoryValue: 'deduction',
                    fieldList: this.deductionList,
                    highlightField: this.deductionHighlightField,
                    notesField: this.deductionNotesField
                },
                {
                    categoryLabel: 'Potongan Pajak',
                    categoryValue: 'tax_deduction',
                    fieldList: this.taxDeductionList,
                    highlightField: this.taxDeductionHighlightField,
                    notesField: this.taxDeductionNotesField
                },
                {
                    categoryLabel: 'Jumlah',
                    categoryValue: 'total_days',
                    fieldList: this.totalDayList,
                    highlightField: this.totalDaysHighlightField,
                    notesField: this.totalDaysNotesField
                },
                {
                    categoryLabel: 'Total',
                    categoryValue: 'total',
                    fieldList: this.totalList,
                    highlightField: this.totalHighlightField,
                    notesField: this.totalNotesField
                },
            ];
        },
        fieldList() {
            return [
                ...this.informationList,
                ...this.incomeList,
                ...this.deductionList,
                ...this.taxDeductionList,
                ...this.totalDayList,
                ...this.totalList
            ];
        },
        countFieldWithoutHighligthAndNotes() {
            return {
                income: this.incomeList.filter((item) => (
                    item && item.field_name !== 'highlight' && item.field_name !== `Notes ${item.type_label}`
                )).length || 0,
                deduction: this.deductionList.filter((item) => (
                    item && item.field_name !== 'highlight' && item.field_name !== `Notes ${item.type_label}`
                )).length || 0,
                total: this.totalList.filter((item) => (
                    item && item.field_name !== 'highlight' && item.field_name !== `Notes ${item.type_label}`
                )).length || 0,
                total_days: this.totalDayList.filter((item) => (
                    item && item.field_name !== 'highlight' && item.field_name !== `Notes ${item.type_label}`
                )).length || 0,
                information: this.informationList.filter((item) => (
                    item && item.field_name !== 'highlight' && item.field_name !== `Notes ${item.type_label}`
                )).length || 0,
                tax_deduction: this.taxDeductionList.filter((item) => (
                    item && item.field_name !== 'highlight' && item.field_name !== `Notes ${item.type_label}`
                )).length || 0
            };
        },
        selectedWorkerPayslipFullname() {
            return this.selectedWorkerPayslip.fullname || '-';
        },
        selectedWorkerPayslipOfficeName() {
            return this.selectedWorkerPayslip.office_name || '-';
        },
        selectedWorkerPayslipRoleName() {
            return this.selectedWorkerPayslip.role_name || '-';
        },
        selectedWorkerPayslipEmployeeId() {
            return this.selectedWorkerPayslip.employee_id || '-';
        },
        selectedWorkerPayslipInformationList() {
            const data = [];

            if (
                typeof this.information &&
                Object.keys(this.information).length > 0 &&
                this.information.constructor === Object
            ) {
                for (const key in this.information) {
                    data.push({
                        type_label: 'Informasi',
                        type_value: 'information',
                        field_name: key,
                        field_value: this.selectedWorkerPayslip.information[key],
                        field_value_type: 'string'
                    });
                }
            }

            return data;
        },
        selectedWorkerPayslipIncomeList() {
            const data = [];

            if (
                typeof this.income &&
                Object.keys(this.income).length > 0 &&
                this.income.constructor === Object
            ) {
                for (const key in this.income) {
                    data.push({
                        type_label: 'Pendapatan',
                        type_value: 'income',
                        field_name: key,
                        field_value: this.selectedWorkerPayslip.income[key],
                        field_value_type: 'curency'
                    });
                }
            }

            return data;
        },
        selectedWorkerPayslipDeductionList() {
            const data = [];

            if (
                typeof this.deduction &&
                Object.keys(this.deduction).length > 0 &&
                this.deduction.constructor === Object
            ) {
                for (const key in this.deduction) {
                    data.push({
                        type_label: 'Potongan',
                        type_value: 'deduction',
                        field_name: key,
                        field_value: this.selectedWorkerPayslip.deduction[key],
                        field_value_type: 'curency'
                    });
                }
            }

            return data;
        },
        selectedWorkerPayslipTaxDeductionList() {
            const data = [];

            if (
                typeof this.taxDeduction &&
                Object.keys(this.taxDeduction).length > 0 &&
                this.taxDeduction.constructor === Object
            ) {
                for (const key in this.taxDeduction) {
                    data.push({
                        type_label: 'Potongan Pajak',
                        type_value: 'tax_deduction',
                        field_name: key,
                        field_value: this.selectedWorkerPayslip.tax_deduction[key],
                        field_value_type: 'curency'
                    });
                }
            }

            return data;
        },
        selectedWorkerPayslipTotalDayList() {
            const data = [];

            if (
                typeof this.totalDays &&
                Object.keys(this.totalDays).length > 0 &&
                this.totalDays.constructor === Object
            ) {
                for (const key in this.totalDays) {
                    data.push({
                        type_label: 'Jumlah',
                        type_value: 'total_days',
                        field_name: key,
                        field_value: this.selectedWorkerPayslip.total_days[key],
                        field_value_type: 'number'
                    });
                }
            }

            return data;
        },
        selectedWorkerPayslipTotalList() {
            const data = [];

            if (
                typeof this.total &&
                Object.keys(this.total).length > 0 &&
                this.total.constructor === Object
            ) {
                for (const key in this.total) {
                    data.push({
                        type_label: 'Total',
                        type_value: 'total',
                        field_name: key,
                        field_value: this.selectedWorkerPayslip.total[key],
                        field_value_type: 'curency'
                    });
                }
            }

            return data;
        },
        selectedInformationHighlightField() {
            let data = {};

            const findHighlightField = this.selectedWorkerPayslipInformationList.find((item) => item && item.field_name === 'highlight');
            if (findHighlightField && findHighlightField.field_value) {
                const findField = this.selectedWorkerPayslipInformationList.find((item) => item && item.field_name === findHighlightField.field_value);
                data = findField || {};
            }

            return data;
        },
        selectedIncomeHighlightField() {
            let data = {};

            const findHighlightField = this.selectedWorkerPayslipIncomeList.find((item) => item && item.field_name === 'highlight');
            if (findHighlightField && findHighlightField.field_value) {
                const findField = this.selectedWorkerPayslipIncomeList.find((item) => item && item.field_name === findHighlightField.field_value);
                data = findField || {};
            }

            return data;
        },
        selectedDeductionHighlightField() {
            let data = {};

            const findHighlightField = this.selectedWorkerPayslipDeductionList.find((item) => item && item.field_name === 'highlight');
            if (findHighlightField && findHighlightField.field_value) {
                const findField = this.selectedWorkerPayslipDeductionList.find((item) => item && item.field_name === findHighlightField.field_value);
                data = findField || {};
            }

            return data;
        },
        selectedTaxDeductionHighlightField() {
            let data = {};

            const findHighlightField = this.selectedWorkerPayslipTaxDeductionList.find((item) => item && item.field_name === 'highlight');
            if (findHighlightField && findHighlightField.field_value) {
                const findField = this.selectedWorkerPayslipTaxDeductionList.find((item) => item && item.field_name === findHighlightField.field_value);
                data = findField || {};
            }

            return data;
        },
        selectedTotalDaysHighlightField() {
            let data = {};

            const findHighlightField = this.selectedWorkerPayslipTotalDayList.find((item) => item && item.field_name === 'highlight');
            if (findHighlightField && findHighlightField.field_value) {
                const findField = this.selectedWorkerPayslipTotalDayList.find((item) => item && item.field_name === findHighlightField.field_value);
                data = findField || {};
            }

            return data;
        },
        selectedTotalHighlightField() {
            let data = {};

            const findHighlightField = this.selectedWorkerPayslipTotalList.find((item) => item && item.field_name === 'highlight');
            if (findHighlightField && findHighlightField.field_value) {
                const findField = this.selectedWorkerPayslipTotalList.find((item) => item && item.field_name === findHighlightField.field_value);
                data = findField || {};
            }

            return data;
        },
        selectedWorkerPayslipInformationNotesField() {
            return this.selectedWorkerPayslipInformationList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        selectedWorkerPayslipIncomeNotesField() {
            return this.selectedWorkerPayslipIncomeList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        selectedWorkerPayslipDeductionNotesField() {
            return this.selectedWorkerPayslipDeductionList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        selectedWorkerPayslipTaxDeductionNotesField() {
            return this.selectedWorkerPayslipTaxDeductionList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        selectedWorkerPayslipTotalDaysNotesField() {
            return this.selectedWorkerPayslipTotalDayList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        selectedWorkerPayslipTotalNotesField() {
            return this.selectedWorkerPayslipTotalList.find((item) => item && item.field_name === `Notes ${item.type_label}`) || {};
        },
        selectedWorkerPayslipCategoryList() {
            return [
                {
                    categoryLabel: 'Informasi',
                    categoryValue: 'information',
                    fieldList: this.selectedWorkerPayslipInformationList,
                    highlightField: this.selectedInformationHighlightField,
                    notesField: this.selectedWorkerPayslipInformationNotesField
                },
                {
                    categoryLabel: 'Pendapatan',
                    categoryValue: 'income',
                    fieldList: this.selectedWorkerPayslipIncomeList,
                    highlightField: this.selectedIncomeHighlightField,
                    notesField: this.selectedWorkerPayslipIncomeNotesField
                },
                {
                    categoryLabel: 'Potongan',
                    categoryValue: 'deduction',
                    fieldList: this.selectedWorkerPayslipDeductionList,
                    highlightField: this.selectedDeductionHighlightField,
                    notesField: this.selectedWorkerPayslipDeductionNotesField
                },
                {
                    categoryLabel: 'Potongan Pajak',
                    categoryValue: 'tax_deduction',
                    fieldList: this.selectedWorkerPayslipTaxDeductionList,
                    highlightField: this.selectedTaxDeductionHighlightField,
                    notesField: this.selectedWorkerPayslipTaxDeductionNotesField
                },
                {
                    categoryLabel: 'Jumlah',
                    categoryValue: 'total_days',
                    fieldList: this.selectedWorkerPayslipTotalDayList,
                    highlightField: this.selectedTotalDaysHighlightField,
                    notesField: this.selectedWorkerPayslipTotalDaysNotesField
                },
                {
                    categoryLabel: 'Total',
                    categoryValue: 'total',
                    fieldList: this.selectedWorkerPayslipTotalList,
                    highlightField: this.selectedTotalHighlightField,
                    notesField: this.selectedWorkerPayslipTotalNotesField
                },
            ];
        },
        selectedWorkerPayslipFieldList() {
            return [
                ...this.selectedWorkerPayslipInformationList,
                ...this.selectedWorkerPayslipIncomeList,
                ...this.selectedWorkerPayslipDeductionList,
                ...this.selectedWorkerPayslipTaxDeductionList,
                ...this.selectedWorkerPayslipTotalDayList,
                ...this.selectedWorkerPayslipTotalList
            ];
        },
        selctedWorkerDetailList() {
            return [
                {
                    label: this.changeField && this.changeField[`employee_id`] ? this.changeField[`employee_id`] : 'Employee ID',
                    value: this.selectedWorkerPayslipEmployeeId
                },
                {
                    label: this.changeField && this.changeField[`fullname`] ? this.changeField[`fullname`] : 'Nama Pekerja',
                    value: this.selectedWorkerPayslipFullname
                },
                {
                    label: this.changeField && this.changeField[`role_name`] ? this.changeField[`role_name`] : 'Job Role',
                    value: this.selectedWorkerPayslipRoleName
                },
                {
                    label: this.changeField && this.changeField[`office_name`] ? this.changeField[`office_name`] : 'Office',
                    value: this.selectedWorkerPayslipOfficeName
                },
            ];
        },
        selectedWorkerHeadingPage() {
            return this.changeField && this.changeField[`heading`] ? this.changeField[`heading`] : 'Payment Receipt';
        },
        disabledButtonAddOrEditField() {
            return (
                this.formField.type === null ||
                this.formField.name === null ||
                !this.checkIsEmpty({ text: this.formField.type }) ||
                !this.checkIsEmpty({ text: this.formField.name }) ||
                (
                    (this.formField.type !== null && this.formField.type === this.selectedField.type_value) &&
                    (this.formField.name !== null && this.formField.name === this.selectedField.field_name)
                ) ||
                this.$validator.errors.any('formField.*')
            );
        },
        disabledButtonCopyTemplateField() {
            let data = true;

            if (
                this.formCopyTemplateField.copied_batch_id !== null ||
                this.$validator.errors.any('formCopyTemplateField.*')
            ) {
                data = false;
            }

            return data;
        },
        disabledButtonAddMitra() {
            if (
                this.formAddWorker.employee_id === null ||
                this.formAddWorker.email === null ||
                this.formAddWorker.fullname === null ||
                this.formAddWorker.identity_number === null ||
                this.formAddWorker.phone_number === null ||
                this.formAddWorker.office_name === null ||
                this.formAddWorker.role_name === null ||
                this.$validator.errors.any('formAddWorker.*')
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {
        async meta() {
            try {
                await this.getPayslipBatchDetail();
            } catch (error) {
                notificationDanger(error);
            }
        }
    },
    beforeMount() {
        if (window.UIkit.modal('.confirmation-modal-box')) window.UIkit.modal('.confirmation-modal-box').$destroy(true);
        if (window.UIkit.modal('.add-or-edit-field-modal-box')) window.UIkit.modal('.add-or-edit-field-modal-box').$destroy(true);
        if (window.UIkit.modal('.copy-template-field-modal-box')) window.UIkit.modal('.copy-template-field-modal-box').$destroy(true);
        if (window.UIkit.modal('.upload-payslip-loader-modal-box')) window.UIkit.modal('.upload-payslip-loader-modal-box').$destroy(true);
        if (window.UIkit.modal('.failed-to-upload-template-modal-box')) window.UIkit.modal('.failed-to-upload-template-modal-box').$destroy(true);
        if (window.UIkit.modal('.add-worker-modal-box')) window.UIkit.modal('.add-worker-modal-box').$destroy(true);
        if (window.UIkit.modal('.edit-worker-payslip-modal-box')) window.UIkit.modal('.edit-worker-payslip-modal-box').$destroy(true);
        if (window.UIkit.modal('.preview-detail-payslip-mitra-modal-box')) window.UIkit.modal('.preview-detail-payslip-mitra-modal-box').$destroy(true);
        if (window.UIkit.modal('.download-detail-payslip-mitra-modal-box')) window.UIkit.modal('.download-detail-payslip-mitra-modal-box').$destroy(true);
        if (window.UIkit.modal('.processing-modal')) window.UIkit.modal('.processing-modal').$destroy(true);
        if (window.UIkit.modal('.edit-warning-letter-modal-box')) window.UIkit.modal('.edit-warning-letter-modal-box').$destroy(true);
    },
    async mounted() {
        try {
            this.isLoading = true;
            await Promise.all([
                this.setFirstActiveTabMenu(),
                this.getBatchOptions(),
                this.getOfficeOptions(),
                this.getRoleOptions(),
                this.setNotesFieldPerCategory(),
            ]);
        } catch (error) {
            notificationDanger(error);
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            showPayslipBatchDetail: 'mitra/showPayslipBatchDetail',
            setFieldAsHighLight: 'mitra/setFieldAsHighLight',
            editPayslipBatchTemplate: 'mitra/editPayslipBatchTemplate',
            downloadPayslipBatchTemplate: 'mitra/downloadPayslipBatchTemplate',
            getPayslipBatchListName: 'mitra/getPayslipBatchListName',
            copyFieldPayslipBatch: 'mitra/copyFieldPayslipBatch',
            uploadExcelPayslip: 'mitra/uploadExcelPayslip',
            downloadPayslipBatchExcel: 'mitra/downloadPayslipBatchExcel',
            getPayslipBatchErrorLogs: 'mitra/getPayslipBatchErrorLogs',
            getCompanyOffice: 'company_office/getCompanyOffice',
            getRoles: "option_data/getRoles",
            addMitraPayslip: 'mitra/addMitraPayslip',
            deletePayslipOnBatch: 'mitra/deletePayslipOnBatch',
            editPayslipOnBatch: 'mitra/editPayslipOnBatch',
            removeFieldAsHighLight: 'mitra/removeFieldAsHighLight',
            distributePayslip: 'mitra/distributePayslip',
            editPayslipBatchWarningLetter: 'mitra/editPayslipBatchWarningLetter',
            editPayslipBatchField: 'mitra/editPayslipBatchField'
        }),
        async setFirstActiveTabMenu() {
            try {
                if (this.$route.query.tab === undefined) await this.setQueryURL('payslip_template');
                this.activeTab = this.$route.query.tab;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setActiveTabMenu(tab) {
            try {
                if (this.$route.query.tab !== undefined) await this.setQueryURL(tab);
                this.activeTab = tab;
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setQueryURL(tab) {
            try {
                await this.$router.replace({
                    path: `/admin/workers/payslip/detail/${this.$route.params.id}`,
                    query: { tab }
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async filterPayslipBatchDetail(payloads) {
            try {
                this.tableLoading = true;
                this.meta.batch_id = this.payslipBatchId;
                this.meta.limit = 10;
                this.meta.page = 1;
                this.meta.worker_name = payloads;
                await this.getPayslipBatchDetail();
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.tableLoading = false;
            }
        },
        async getPayslipBatchDetail() {
            try {
                const response = await this.showPayslipBatchDetail(this.meta);
                if (response && response.status === 'OK' && response.result) {
                    const result = response.result;
                    this.payslipBatchDetail = result;
                    this.checkEmptyField();
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setNotesFieldPerCategory() {
            try {
                await this.getPayslipBatchDetail();

                const payloads = {
                    batch_id: this.payslipBatchId,
                    income: {},
                    deduction: {},
                    total: {},
                    total_days: {},
                    information: {},
                    tax_deduction: {}
                };

                if (Array.isArray(this.fieldList) && this.fieldList.length > 0) {
                    this.fieldList.forEach((item) => {
                        if (item.field_name !== 'highlight') {
                            payloads[item.type_value][item.field_name] = null;

                            delete payloads[item.type_value][`Notes ${item.type_label}`];

                            payloads[item.type_value][item.field_name] = null;
                            payloads[item.type_value][`Notes ${item.type_label}`] = null;
                        } else {
                            payloads[item.type_value][item.field_name] = item.field_value;
                        }
                    });
                }

                const response = await this.editPayslipBatchTemplate(payloads);
                if (response && response.status === 'OK' && response.result === 'success') {
                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showAddOrEditFieldModalBox({ action, typeLabel, typeValue, fieldName }) {
            try {
                await this.getPayslipBatchDetail();

                this.actionTypeModalBox = action;
                this.resetFormField();
                this.resetSelectedField();

                this.formField.type = typeValue;
                this.formField.name = fieldName;

                this.selectedField.type_label = typeLabel;
                this.selectedField.type_value = typeValue;
                this.selectedField.field_name = fieldName;

                await window.UIkit.modal('#add-or-edit-field-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideAddOrEditFieldModalBox() {
            try {
                this.actionTypeModalBox = null;
                this.resetFormField();
                this.resetSelectedField();
                await window.UIkit.modal('#add-or-edit-field-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doEditPayslipBatchTemplate() {
            try {
                const payloads = {
                    batch_id: this.payslipBatchId,
                    income: {},
                    deduction: {},
                    total: {},
                    total_days: {},
                    information: {},
                    tax_deduction: {}
                };

                const payloadsChangeFieldName = {
                    batch_id: this.payslipBatchId,
                    type: this.formField.type,
                    value: this.formField.name
                };

                if (!this.isChangeFieldTypeExist(this.formField.type)) {
                    if (Array.isArray(this.fieldList) && this.fieldList.length > 0) {
                        this.fieldList.forEach((item) => {
                            if (item.field_name !== 'highlight') {
                                payloads[item.type_value][item.field_name] = null;
                            } else {
                                payloads[item.type_value][item.field_name] = item.field_value;
                            }
                        });
                    }

                    if (this.actionTypeModalBox === 'add-field' || this.actionTypeModalBox === 'edit-field') {
                        const validate = await this.$validator.validateAll('formField.*');
                        if (!validate || this.$validator.errors.any('formField.*')) return;
                        this.fieldNameValidator({ fieldName: this.formField.name });
                    }

                    if (this.actionTypeModalBox === 'add-field') {
                        delete payloads[this.selectedField.type_value][`Notes ${this.selectedField.type_label}`];

                        payloads[this.formField.type][this.formField.name] = null;
                        payloads[this.selectedField.type_value][`Notes ${this.selectedField.type_label}`] = null;
                    } else if (this.actionTypeModalBox === 'edit-field') {
                        delete payloads[this.selectedField.type_value][this.selectedField.field_name];
                        delete payloads[this.selectedField.type_value][`Notes ${this.selectedField.type_label}`];

                        payloads[this.formField.type][this.formField.name] = null;
                        payloads[this.selectedField.type_value][`Notes ${this.selectedField.type_label}`] = null;

                        if (this.selectedField.field_name === payloads[this.selectedField.type_value]['highlight']) {
                            payloads[this.selectedField.type_value]['highlight'] = this.formField.name;
                        }
                    } else if (this.actionTypeModalBox === 'delete-field') {
                        delete payloads[this.selectedField.type_value][this.selectedField.field_name];

                        if (this.countFieldWithoutHighligthAndNotes[this.selectedField.type_value] <= 1) {
                            delete payloads[this.selectedField.type_value][`Notes ${this.selectedField.type_label}`];
                        }

                        if (this.selectedField.field_name === payloads[this.selectedField.type_value]['highlight']) {
                            delete payloads[this.selectedField.type_value]['highlight'];
                        }
                    }
                }

                this.addOrEditFieldButtonLoading = true;
                const response = this.isChangeFieldTypeExist(this.formField.type) ? await this.editPayslipBatchField(payloadsChangeFieldName) : await this.editPayslipBatchTemplate(payloads);
                if (response && response.status === 'OK' && response.result === 'success') {
                    await this.hideAddOrEditFieldModalBox();

                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;

                    if (this.actionTypeModalBox === 'add-field') {
                        notificationSuccess('Field added successfully');
                    } else if (this.actionTypeModalBox === 'edit-field') {
                        notificationSuccess('Field updated successfully');
                    } else if (this.actionTypeModalBox === 'delete-field') {
                        notificationSuccess('Field deleted successfully');
                    }
                } else {
                    if (this.actionTypeModalBox === 'add-field') {
                        notificationDangerCustom('Failed to add field');
                    } else if (this.actionTypeModalBox === 'edit-field') {
                        notificationDangerCustom('Failed to update field');
                    } else if (this.actionTypeModalBox === 'delete-field') {
                        notificationDangerCustom('Failed to delete field');
                    }
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.addOrEditFieldButtonLoading = false;
            }
        },
        async deleteField({ action, typeLabel, typeValue, fieldName }) {
            try {
                await this.getPayslipBatchDetail();

                this.actionTypeModalBox = action;
                this.selectedField.type_label = typeLabel;
                this.selectedField.type_value = typeValue;
                this.selectedField.field_name = fieldName;

                await this.showConfirmationModalBox({
                    title: 'Confirmation',
                    message: 'Are you sure want to delete this entry?'
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async setAsHighlight({ action, typeLabel, typeValue, fieldName }) {
            try {
                await this.getPayslipBatchDetail();

                this.actionTypeModalBox = action;
                this.selectedField.type_label = typeLabel;
                this.selectedField.type_value = typeValue;
                this.selectedField.field_name = fieldName;

                await this.showConfirmationModalBox({
                    title: 'Confirmation',
                    message: `Are you sure want to set “${this.preview({ data: fieldName, limit: 50 })}” as highlight for ${this.preview({ data: typeLabel, limit: 50 })} section?`
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async removeFieldAsHighlight({ action, typeLabel, typeValue, fieldName }) {
            try {
                await this.getPayslipBatchDetail();

                this.actionTypeModalBox = action;
                this.selectedField.type_label = typeLabel;
                this.selectedField.type_value = typeValue;
                this.selectedField.field_name = fieldName;

                await this.showConfirmationModalBox({
                    title: 'Confirmation',
                    message: `Are you sure want to remove “${this.preview({ data: fieldName, limit: 50 })}” as highlight for ${this.preview({ data: typeLabel, limit: 50 })} section?`
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showConfirmationModalBox({ title, message }) {
            try {
                this.confirmationModalBoxData.title = title;
                this.confirmationModalBoxData.message = message;
                await window.UIkit.modal('#confirmation-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideConfirmationModalBox() {
            try {
                this.actionTypeModalBox = null;
                this.confirmationModalBoxData.title = null;
                this.confirmationModalBoxData.message = null;
                await window.UIkit.modal('#confirmation-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doConfirmation() {
            try {
                this.confirmationModalBoxButtonLoading = true;

                if (this.actionTypeModalBox === 'set-as-highlight') {
                    await this.doSetAsHighlight();
                } else if (this.actionTypeModalBox === 'delete-field') {
                    await this.doEditPayslipBatchTemplate();
                } else if (this.actionTypeModalBox === 'remove-field-as-highlight') {
                    await this.doRemoveFieldAsHighlight();
                } else if (this.actionTypeModalBox === 'delete-worker-payslip') {
                    await this.doDeleteWorkerPayslip();
                } 
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.confirmationModalBoxButtonLoading = false;
                await this.hideConfirmationModalBox();
            }
        },
        async doSetAsHighlight() {
            try {
                const payloads = {
                    batch_id: this.payslipBatchId,
                    type: this.selectedField.type_value,
                    field: this.selectedField.field_name
                };

                const response = await this.setFieldAsHighLight(payloads);
                if (response && response.status === 'OK' && response.result === 'success') {
                    this.resetSelectedField();

                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;

                    notificationSuccess('The field was successfully set as highlight');
                } else {
                    notificationDangerCustom('Set as highlight failed');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doRemoveFieldAsHighlight() {
            try {
                const payloads = {
                    batch_id: this.payslipBatchId,
                    type: this.selectedField.type_value
                };

                const response = await this.removeFieldAsHighLight(payloads);
                if (response && response.status === 'OK' && response.result === 'success') {
                    this.resetSelectedField();

                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;

                    notificationSuccess('The field was successfully removed as a highlight');
                } else {
                    notificationDangerCustom('Remove highlight failed');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async getBatchOptions() {
            try {
                const result = await this.getPayslipBatchListName();
                this.batchOptions = result.filter((batch) => batch && batch._id != this.$route.params.id).map((batch) => ({
                    value: batch && batch._id,
                    label: batch && batch.name
                }));
            } catch (error) {
                notificationDanger(error);
            }
        },
        async getOfficeOptions() {
            try {
                await this.getCompanyOffice();
                this.officeOptions = this.company_offices;
                if (this.officeOptions && this.officeOptions.length > 0) {
                    this.officeOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                    this.officeOptions = [...new Set(this.officeOptions.map(item => String(item.name)))];
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async getRoleOptions() {
            try {
                await this.getRoles();
                this.roleOptions = this.roles;
                if (this.roleOptions && this.roleOptions.length > 0) {
                    this.roleOptions.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase()) ? 1 : ((b.name.toUpperCase() > a.name.toUpperCase()) ? -1 : 0));
                    this.roleOptions = [...new Set(this.roleOptions.map(item => String(item.name)))];
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showEditWarningLetterModalBox() {
            try {
                this.actionTypeModalBox = null;
                this.formWarningLetter.warning_letter = this.payslipBatchWarningLetter;
                await window.UIkit.modal('#edit-warning-letter-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideEditWarningLetterModalBox() {
            try {
                this.actionTypeModalBox = null;
                this.formWarningLetter.warning_letter = null;
                const matcher = {
                    scope: 'formWarningLetter',
                    vmId: this.$validator.id
                };
                this.$validator.reset(matcher);
                await window.UIkit.modal('#edit-warning-letter-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doEditWarningLetter() {
            try {
                const validate = await this.$validator.validateAll('formWarningLetter.*');
                if (!validate || this.$validator.errors.any('formWarningLetter.*')) return;

                const payload = { batch_id: this.payslipBatchId, warning_letter: this.formWarningLetter.warning_letter };
                const response = await this.editPayslipBatchWarningLetter(payload);
                if (response && response.status === 'OK' && response.result === 'success') {
                    await this.hideEditWarningLetterModalBox();

                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;

                    notificationSuccess('Warning letter updated successfully');
                } else {
                    notificationDangerCustom('Failed to update warning letter!');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showCopyFieldTemplateModalBox() {
            try {
                this.actionTypeModalBox = 'copy-field-template';
                await window.UIkit.modal('#copy-template-field-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideCopyTemplateFieldModalBox() {
            try {
                this.actionTypeModalBox = null;
                this.formCopyTemplateField.copied_batch_id = null;
                const matcher = {
                    scope: 'formCopyTemplateField',
                    vmId: this.$validator.id
                };
                this.$validator.reset(matcher);
                await window.UIkit.modal('#copy-template-field-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doCopyTemplateField() {
            try {
                const validate = await this.$validator.validateAll('formCopyTemplateField.*');
                if (!validate || this.$validator.errors.any('formCopyTemplateField.*')) return;

                this.copyTemplateFieldButtonLoading = true;

                const payload = { batch_id: this.payslipBatchId, copied_batch_id: this.formCopyTemplateField.copied_batch_id };
                const response = await this.copyFieldPayslipBatch(payload);
                if (response && response.status === 'OK' && response.result === 'success') {
                    await this.hideCopyTemplateFieldModalBox();

                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;

                    notificationSuccess('Field copied successfully');
                } else {
                    notificationDangerCustom('Failed to copy field!');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.copyTemplateFieldButtonLoading = false;
            }
        },
        async doDownloadPayslipBatchTemplate() {
            try {
                this.downloadPayslipBatchTemplateLoading = true;
                const params = { id: this.payslipBatchId };
                const response = await this.downloadPayslipBatchTemplate(params);
                if (response && response.status === 'OK' && response.result && response.template) {
                    const { file_name, income, deduction, total, total_days, information, tax_deduction, is_dynamic_warning_per_record } = response.result;
                    const template = response.template;

                    let dataTemplate = {};
                    dataTemplate['User ID'] = template['User ID'];
                    dataTemplate['Employee ID'] = template['Employee ID'];
                    dataTemplate['Nama'] = template['Nama'];
                    dataTemplate['Email'] = template['Email'];
                    dataTemplate['No Telp'] = template['No Telp'];
                    dataTemplate['NIK'] = template['NIK'];
                    dataTemplate['Office'] = template['Office'];
                    dataTemplate['Role'] = template['Role'];

                    if (typeof information === 'object' && Object.keys(information).length > 0 && information.constructor === Object) {
                        for (const informationKey in information) {
                            dataTemplate[informationKey] = information[informationKey];
                        }
                    }

                    if (typeof income === 'object' && Object.keys(income).length > 0 && income.constructor === Object) {
                        for (const incomeKey in income) {
                            dataTemplate[incomeKey] = income[incomeKey];
                        }
                    }

                    if (typeof deduction === 'object' && Object.keys(deduction).length > 0 && deduction.constructor === Object) {
                        for (const deductionKey in deduction) {
                            dataTemplate[deductionKey] = deduction[deductionKey];
                        }
                    }

                    if (typeof tax_deduction === 'object' && Object.keys(tax_deduction).length > 0 && tax_deduction.constructor === Object) {
                        for (const taxDeductionKey in tax_deduction) {
                            dataTemplate[taxDeductionKey] = tax_deduction[taxDeductionKey];
                        }
                    }

                    if (typeof total_days === 'object' && Object.keys(total_days).length > 0 && total_days.constructor === Object) {
                        for (const totalDayKey in total_days) {
                            dataTemplate[totalDayKey] = total_days[totalDayKey];
                        }
                    }

                    if (typeof total === 'object' && Object.keys(total).length > 0 && total.constructor === Object) {
                        for (const totalKey in total) {
                            dataTemplate[totalKey] = total[totalKey];
                        }
                    }

                    if (is_dynamic_warning_per_record) {
                        dataTemplate['Warning Letter'] = template['Warning Letter'];
                    }

                    const dataExcel = [dataTemplate];
                    excelDownloader(dataExcel, file_name);
                    notificationSuccess('Template downloaded successfully');
                } else {
                    notificationDangerCustom('Template failed to download');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadPayslipBatchTemplateLoading = false;
            }
        },
        async uploadTemplate(event) {
            try {
                if (event && event.target && event.target.files && event.target.files[0]) {
                    await window.UIkit.modal('#upload-payslip-loader-modal-box').show();
                    this.uploadTemplateLoading = true;

                    const filePath = event.target.files[0];
                    const data = new FormData();
                    data.append('id', this.$route.params.id);
                    data.append('excel', filePath);

                    const response = await this.uploadExcelPayslip(data);
                    if (response && response.status === 'OK') {
                        data.delete('id');
                        data.delete('excel');
                        event.target.value = null;

                        if (
                            response &&
                            response.rejected_data &&
                            Array.isArray(response.rejected_data) &&
                            response.rejected_data.length > 0
                        ) {
                            const filenameExcel = filePath?.name;
                            const rejectedData = response?.rejected_data;
                            this.filenameExcel = filenameExcel;
                            this.rejectedData = rejectedData;

                            await window.UIkit.modal('#upload-payslip-loader-modal-box').hide();
                            this.uploadTemplateLoading = false;

                            await window.UIkit.modal('#failed-to-upload-template-modal-box').show();
                        } else {
                            notificationSuccess('Upload template successfully');
                        }
                    } else {
                        notificationDangerCustom('Failed to upload');
                    }
                } else {
                    await window.UIkit.modal('#upload-payslip-loader-modal-box').hide();
                    this.uploadTemplateLoading = false;

                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#upload-payslip-loader-modal-box').hide();
                this.uploadTemplateLoading = false;

                this.tableLoading = true;
                await this.getPayslipBatchDetail();
                this.tableLoading = false;
            }
        },
        async hideFailedToUploadModalBox() {
            try {
                this.filenameExcel = null;
                this.rejectedData = [];
                this.uploadTemplateLoading = false;

                this.tableLoading = true;
                await this.getPayslipBatchDetail();
                this.tableLoading = false;

                await Promise.all([
                    window.UIkit.modal('#upload-payslip-loader-modal-box').hide(),
                    window.UIkit.modal('#failed-to-upload-template-modal-box').hide()
                ]);
            } catch (error) {
                notificationDanger(error);
            }
        },
        async downloadRejectedData() {
            try {
                this.downloadRejectedDataButtonLoading = true;
                const fileName = `(Rejected data) ${this.filenameExcel}`;
                excelDownloader(this.rejectedData, fileName);
                notificationSuccess('Report successfully downloaded');
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadRejectedDataButtonLoading = false;
            }
        },
        async downloadReportPayslipBatchExcel() {
            try {
                this.downloadReportPayslipBatchExcelButtonLoading = true;
                const params = { id: this.payslipBatchId };
                const response = await this.downloadPayslipBatchExcel(params);
                if (response && response.status === 'OK' && response.result && response.result.file_name && response.result.docs) {
                    const fileName = response.result.file_name;
                    const docs = response.result.docs;
                    const dataExcel = [];
                    if (docs.length > 0) {
                        docs.forEach((doc) => {
                            dataExcel.push(doc);
                        });
                    } else {
                        dataExcel.push(docs);
                    }
                    excelDownloader(dataExcel, fileName);
                    notificationSuccess('Report successfully downloaded');
                } else {
                    notificationDangerCustom('Report failed to download');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.downloadReportPayslipBatchExcelButtonLoading = false;
            }
        },
        async showLogsModalBox() {
            try {
                const params = { id: this.payslipBatchId };
                const response = await this.getPayslipBatchErrorLogs(params);
                if (
                    response &&
                    response.status === 'OK' &&
                    response.result &&
                    response.result.rejected_data &&
                    Array.isArray(response.result.rejected_data) &&
                    response.result.rejected_data.length > 0
                ) {
                    this.filenameExcel = response.result.filename;
                    this.rejectedData = response.result.rejected_data;
                    await window.UIkit.modal('#failed-to-upload-template-modal-box').show();
                } else {
                    notificationSuccess('Payslip Mitra 100% Berhasil Terupload');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showAddWorkerModalBox() {
            try {
                await this.getPayslipBatchDetail();
                this.resetFormAddWorker();

                if (Array.isArray(this.fieldList) && this.fieldList.length > 0) {
                    this.fieldList.forEach((item) => {
                        if (item.field_name !== 'highlight') {
                            if (item.type_value === 'information') {
                                this.formAddWorker[item.type_value][item.field_name] = null;
                            } else {
                                if (item.field_name === `Notes ${item.type_label}`) {
                                    this.formAddWorker[item.type_value][item.field_name] = null;
                                } else {
                                    this.formAddWorker[item.type_value][item.field_name] = this.checkFieldValue({ value: null });
                                }
                            }
                        } else {
                            this.formAddWorker[item.type_value][item.field_name] = item.field_value;
                        }
                    });
                }

                await window.UIkit.modal('#add-worker-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideAddWorkerModalBox() {
            try {
                this.resetFormAddWorker();
                await window.UIkit.modal('#add-worker-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doAddWorker() {
            try {
                const validate = await this.$validator.validateAll('formAddWorker.*');
                if (!validate || this.$validator.errors.any('formAddWorker.*')) return;

                this.addWorkerButtonLoading = true;                

                const payloads = {
                    batch_id: this.payslipBatchId,
                    ...this.formAddWorker
                };

                const response = await this.addMitraPayslip(payloads);
                if (response && response.status === 'OK' && response.message === 'success') {
                    await this.hideAddWorkerModalBox();

                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;

                    notificationSuccess('Mitra added successfully');
                } else {
                    notificationDangerCustom('Failed to add worker, worker payslip already exists!');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.addWorkerButtonLoading = false;
            }
        },
        async deleteWorkerPayslip({ action, workerPayslip }) {
            try {
                await this.getPayslipBatchDetail();

                this.actionTypeModalBox = action;
                this.resetSelectedWorkerPayslip();

                const { payslip_batch_id, _id } = workerPayslip;
                this.selectedWorkerPayslip.batch_id = payslip_batch_id;
                this.selectedWorkerPayslip.slip_id = _id;

                await this.showConfirmationModalBox({
                    title: 'Confirmation',
                    message: 'Are you sure want to delete this entry?'
                });
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doDeleteWorkerPayslip() {
            try {
                const response = await this.deletePayslipOnBatch(this.selectedWorkerPayslip);
                if (response && response.status === 'OK' && response.result === 'success') {
                    this.actionTypeModalBox = null;
                    this.resetSelectedWorkerPayslip();

                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;

                    notificationSuccess('Payslip deleted successfully');
                } else {
                    notificationDangerCustom('Payslip failed to delete');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        async previewWorkerPayslip({ action, workerPayslip }) {
            try {
                await this.getPayslipBatchDetail();
                this.resetSelectedWorkerPayslip();

                this.actionTypeModalBox = action;

                const {
                    payslip_batch_id,
                    _id,
                    employee_id,
                    fullname,
                    office_name,
                    role_name,
                    income,
                    deduction,
                    total,
                    total_days,
                    information,
                    tax_deduction,
                    warning_letter
                } = workerPayslip;

                this.selectedWorkerPayslip.batch_id = payslip_batch_id;
                this.selectedWorkerPayslip.slip_id = _id;
                this.selectedWorkerPayslip.employee_id = employee_id;
                this.selectedWorkerPayslip.fullname = fullname;
                this.selectedWorkerPayslip.office_name = office_name;
                this.selectedWorkerPayslip.role_name = role_name;
                this.selectedWorkerPayslip.income = income;
                this.selectedWorkerPayslip.deduction = deduction;
                this.selectedWorkerPayslip.total = total;
                this.selectedWorkerPayslip.total_days = total_days;
                this.selectedWorkerPayslip.information = information;
                this.selectedWorkerPayslip.tax_deduction = tax_deduction;
                this.selectedWorkerPayslip.warning_letter = this.payslipBatchIsDynamicWarningPerRecord ? warning_letter : this.payslipBatchWarningLetter;

                await window.UIkit.modal('#preview-detail-payslip-mitra-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hidePreviewWorkerPayslipModalBox() {
            try {
                this.resetSelectedWorkerPayslip();
                await window.UIkit.modal('#preview-detail-payslip-mitra-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showDownloadWorkerPayslipModalBox() {
            try {
                await window.UIkit.modal('#download-detail-payslip-mitra-modal-box', { stack: true }).show();

                const employeeId = this.selectedWorkerPayslip.employee_id;
                const employeeName = this.selectedWorkerPayslip.fullname;

                document.title = `Payslip-${employeeName}-${employeeId}`;
                window.print();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideDownloadWorkerPayslipModalBox() {
            try {
                await window.UIkit.modal('#download-detail-payslip-mitra-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async showEditWorkerPayslipModalBox({ action, workerPayslip }) {
            try {
                await this.getPayslipBatchDetail();
                this.resetSelectedWorkerPayslip();
                this.resetFormEditWorkerPayslip();

                this.actionTypeModalBox = action;

                const {
                    payslip_batch_id,
                    _id,
                    employee_id,
                    fullname,
                    office_name,
                    role_name,
                    income,
                    deduction,
                    total,
                    total_days,
                    information,
                    tax_deduction,
                    warning_letter
                } = workerPayslip;

                this.selectedWorkerPayslip.batch_id = payslip_batch_id;
                this.selectedWorkerPayslip.slip_id = _id;
                this.selectedWorkerPayslip.employee_id = employee_id;
                this.selectedWorkerPayslip.fullname = fullname;
                this.selectedWorkerPayslip.office_name = office_name;
                this.selectedWorkerPayslip.role_name = role_name;
                this.selectedWorkerPayslip.income = income || this.income;
                this.selectedWorkerPayslip.deduction = deduction || this.deduction;
                this.selectedWorkerPayslip.total = total || this.total;
                this.selectedWorkerPayslip.total_days = total_days || this.totalDays;
                this.selectedWorkerPayslip.information = information || this.information;
                this.selectedWorkerPayslip.tax_deduction = tax_deduction || this.taxDeduction;

                if (this.payslipBatchIsDynamicWarningPerRecord) {                
                    this.formEditWorkerPayslip.warning_letter = warning_letter;
                }

                if (Array.isArray(this.selectedWorkerPayslipFieldList) && this.selectedWorkerPayslipFieldList.length > 0) {
                    this.selectedWorkerPayslipFieldList.forEach((item) => {
                        if (item.field_name !== 'highlight') {
                            if (item.type_value === 'information') {
                                this.formEditWorkerPayslip[item.type_value][item.field_name] = item.field_value || null;
                            } else {
                                if (item.field_name === `Notes ${item.type_label}`) {
                                    this.formEditWorkerPayslip[item.type_value][item.field_name] = item.field_value || null;
                                } else {
                                    this.formEditWorkerPayslip[item.type_value][item.field_name] = item.field_value ?? null;
                                }
                            }
                        } else {
                            this.formEditWorkerPayslip[item.type_value][item.field_name] = item.field_value;
                        }
                    });
                }

                await window.UIkit.modal('#edit-worker-payslip-modal-box').show();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async hideEditWorkerPayslipModalBox() {
            try {
                this.actionTypeModalBox = null;
                this.resetSelectedWorkerPayslip();
                this.resetFormEditWorkerPayslip();
                await window.UIkit.modal('#edit-worker-payslip-modal-box').hide();
            } catch (error) {
                notificationDanger(error);
            }
        },
        async doEditWorkerPayslip() {
            try {
                const validate = await this.$validator.validateAll('formEditWorkerPayslip.*');
                if (!validate || this.$validator.errors.any('formEditWorkerPayslip.*')) return;

                this.editWorkerPayslipButtonLoading = true;

                const payloads = {
                    batch_id: this.selectedWorkerPayslip.batch_id,
                    slip_id: this.selectedWorkerPayslip.slip_id,
                    ...this.formEditWorkerPayslip
                };

                const response = await this.editPayslipOnBatch(payloads);
                if (response && response.status === 'OK' && response.result === 'success') {
                    await this.hideEditWorkerPayslipModalBox();

                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;

                    notificationSuccess('Payslip updated successfully');
                } else {
                    notificationDangerCustom('Payslip failed to update');
                }
            } catch (error) {
                notificationDanger(error);
            } finally {
                this.editWorkerPayslipButtonLoading = false;
            }
        },
        resetFormAddWorker() {
            this.formAddWorker.employee_id = null;
            this.formAddWorker.email = null;
            this.formAddWorker.fullname = null;
            this.formAddWorker.identity_number = null;
            this.formAddWorker.phone_number = null;
            this.formAddWorker.office_name = null;
            this.formAddWorker.role_name = null;
            this.formAddWorker.income = {};
            this.formAddWorker.deduction = {};
            this.formAddWorker.total = {};
            this.formAddWorker.total_days = {};
            this.formAddWorker.information = {};
            this.formAddWorker.tax_deduction = {};
            if (this.payslipBatchIsDynamicWarningPerRecord) {                
                this.formAddWorker.warning_letter = DEFAULT_WARNING_LETTER_PAYSLIP;
            } else {
                delete this.formAddWorker.warning_letter;
            }
            const matcher = {
                scope: 'formAddWorker',
                vmId: this.$validator.id
            };
            this.$validator.reset(matcher);            
        },
        resetFormField() {
            this.formField.type = null;
            this.formField.name = null;
            const matcher = {
                scope: 'formField',
                vmId: this.$validator.id
            };
            this.$validator.reset(matcher);
        },
        resetSelectedField() {
            this.selectedField.type_label = null;
            this.selectedField.type_value = null;
            this.selectedField.field_name = null;
        },
        resetSelectedWorkerPayslip() {
            this.selectedWorkerPayslip.batch_id = null;
            this.selectedWorkerPayslip.slip_id = null;
            this.selectedWorkerPayslip.employee_id = null;
            this.selectedWorkerPayslip.fullname = null;
            this.selectedWorkerPayslip.office_name = null;
            this.selectedWorkerPayslip.role_name = null;
            this.selectedWorkerPayslip.income = {};
            this.selectedWorkerPayslip.deduction = {};
            this.selectedWorkerPayslip.total = {};
            this.selectedWorkerPayslip.total_days = {};
            this.selectedWorkerPayslip.information = {};
            this.selectedWorkerPayslip.tax_deduction = {};
        },
        resetFormEditWorkerPayslip() {
            this.formEditWorkerPayslip.income = {};
            this.formEditWorkerPayslip.deduction = {};
            this.formEditWorkerPayslip.total = {};
            this.formEditWorkerPayslip.total_days = {};
            this.formEditWorkerPayslip.information = {};
            this.formEditWorkerPayslip.tax_deduction = {};
            if (this.payslipBatchIsDynamicWarningPerRecord) {                
                this.formEditWorkerPayslip.warning_letter = DEFAULT_WARNING_LETTER_PAYSLIP;
            } else {
                delete this.formEditWorkerPayslip.warning_letter;
            }
            const matcher = {
                scope: 'formEditWorkerPayslip',
                vmId: this.$validator.id
            };
            this.$validator.reset(matcher);
        },
        async onDistributeWorkerPayslip({ action, workerPayslip }) {
            try {
                const { payslip_batch_id, _id } = workerPayslip;
                this.selectedWorkerPayslip.batch_id = payslip_batch_id;
                this.selectedWorkerPayslip.slip_id = _id;
                this.actionTypeModalBox = action;
                await window.UIkit.modal('#processing-modal').show();
                await this.doDistributeWorkerPayslip();
            } catch (error) {
                notificationDanger(error);
            } finally {
                await window.UIkit.modal('#processing-modal').hide();
            }
        },
        async doDistributeWorkerPayslip() {
            try {
                const response = await this.distributePayslip(this.selectedWorkerPayslip);
                if (response && response.status === 'OK' && response.result === 'success') {
                    this.actionTypeModalBox = null;
                    this.resetSelectedWorkerPayslip();

                    this.tableLoading = true;
                    await this.getPayslipBatchDetail();
                    this.tableLoading = false;

                    notificationSuccess('Payslip successfully blasted');
                } else {
                    notificationDangerCustom('Payslip failed to blast');
                }
            } catch (error) {
                notificationDanger(error);
            }
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        checkEmptyField() {
            if (
                this.payslips.totalDocs < 1 &&
                this.incomeList.length < 1 &&
                this.deductionList.length < 1 &&
                this.totalList.length < 1 &&
                this.totalDayList.length < 1 &&
                this.informationList.length < 1 &&
                this.taxDeductionList.length < 1
            ) {
                this.isEmptyField = true;
            } else {
                this.isEmptyField = false;
            }
        },
        fieldNameValidator({ fieldName }) {
            const findFieldName = this.fieldList.find((field) => (
                this.removeSpecialCharacter({
                    string: field?.field_name?.trim()?.toLowerCase()
                }) === this.removeSpecialCharacter({
                    string: fieldName?.trim()?.toLowerCase()
                })
            ));

            if (findFieldName) {
                throw new Error('Field name already exist');
            } else {
                return;
            }
        },
        checkFieldValue({ value }) {
            if (value !== null) {
                return value;
            } else {
                return parseInt(0);
            }
        },
        removeSpecialCharacter({ string }) {
            return string.replace(/[^a-zA-Z0-9]/g, "");
        },
        checkIsEmpty({ text }) {
            return (/([^\s])/).test(text);
        },
        preview({ data, limit }) {
            if (typeof data === 'string') {
                if (data.length < limit) {
                    return data;
                } else {
                    return data.substring(0, limit)+'...';
                }
            } else {
                return '-';
            }
        },
        getMonth(month) {
            return formatter.getMonthString({ index: month });
        },
        getCurrency(salary) {
            return formatter.salaryCurrency(salary);
        },
        goBack() {
            this.$router.back();
        },
        changeFieldTypeLabel(key) {            
            switch (key) {
            case 'heading':
                return 'Payslip / Payment Receipt';
            case 'employee_id':
                return 'Employee ID';
            case 'fullname':
                return 'Nama Pekerja';
            case 'role_name':
                return 'Job Role';
            case 'office_name':
                return 'Office';
            default:
                return '-';
            }
        },
        isChangeFieldTypeExist(key) {
            return ['heading', 'employee_id', 'fullname', 'role_name', 'office_name'].includes(key);
        }
    }
};
</script>

<style scoped>
.myrobin-background-primary {
    background: #0ABAB5;
}
.myrobin-background-white {
    background: white;
}

.myrobin-color-blue {
    color: #0275D8;
}
.myrobin-color-white {
    color: #FFFFFF;
}
.myrobin-color-aqua {
    color: #0C9389;
}
.myrobin-color-grey {
    color: #9A9A9A;
}

.myrobin-border-blue {
    border: 1px solid #0275D8;
}
.myrobin-border-grey {
    border: 1px solid #9A9A9A;
}

.gap-5px {
    gap: 5px;
}
.gap-10px {
    gap: 10px;
}
.gap-20px {
    gap: 20px;
}

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.loader {
    border: 10px solid #16d8d2;
    border-radius: 50%;
    border-top: 10px solid #0ABAB5;
    width: 100px;
    height: 100px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

.highlight-component {
    background-color: rgba(245, 246, 246, 1);
    color: rgba(55, 71, 79, 1);
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
}

* {
  -webkit-print-color-adjust: exact !important;
  /* Chrome, Safari */
  print-color-adjust: exact !important;
  /*Firefox*/
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.worker-detail {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.worker-detail-card-item {
    border-radius: 6px !important;
    background-color: #F5F6F6 !important;
    padding: 10px 15px;
}

.payslip-detail {
    width: 100% !important;
    height: auto !important;
    background-size: contain !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-image: url('/images/payslip-watermark.png') !important;
}

.download-detail-payslip-mitra-modal-box {
    color: black;
    font-family: Poppins;
}

.payslip-warning-box {
    color: #F44336;
    background-color: rgba(255, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    margin: 0px 15px;
}
.notif-bold {
    color: #F44336 !important;
    text-transform: uppercase;
    font-weight: bold;
}

#containerHtml p {
    color: #F44336 !important;
}

@media print {
    @page {
        size: A4 !important;
        margin: 0px !important;
        padding: 0px;
    }

    .main, .main *:not(.download-detail-payslip-mitra-modal-box):not(.download-detail-payslip-mitra-modal-box *) {
        display: none !important;
        visibility: hidden !important;
        overflow: hidden !important;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }

    .preview-detail-payslip-mitra-modal-box, .preview-detail-payslip-mitra-modal-box * {
        display: none !important;
        visibility: hidden !important;
        overflow: hidden !important;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }

    .download-detail-payslip-mitra-modal-box {
        visibility: visible !important;
        overflow: visible !important;
        width: 100vw !important;
        height: auto !important;
        position: absolute !important;
        left: 0 !important;
        top: 0 !important;
        background-color: white !important;
        color: #333 !important;
    }

    .download-detail-payslip-mitra-modal-box > div {
        background-color: white !important;
        width: 100% !important;
        /* padding: 40px 0 !important; */
    }

    .download-detail-payslip-mitra-modal-box * {
        visibility: visible !important;
        overflow: visible !important;
    }

    .worker-detail-card-item {
        border-radius: 6px !important;
        background-color: #F5F6F6 !important;
        padding: 10px 15px;
    }

    .payslip-detail {
        width: 100% !important;
        height: auto !important;
        background-size: contain !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-image: url('/images/payslip-watermark.png') !important;
    }

    .no-print-me {
        display: none !important;
    }

    .line-clamp-1 {
        display: -webkit-box !important;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical !important;  
        overflow: hidden !important;
    }
    .line-clamp-3 {
        display: -webkit-box !important;
        -webkit-line-clamp: 3 !important;
        -webkit-box-orient: vertical !important;  
        overflow: hidden !important;
    }

    .payslip-warning-box {
        border: solid 1px rgba(255, 0, 0, 0.1) !important;
        color: #F44336 !important;
        background-color: rgba(255, 0, 0, 0.1) !important;
        border-radius: 10px;
        padding: 15px;
        margin: 0px 15px;
        display: block;
    }
    .notif-bold {
        color: #F44336 !important;
        text-transform: uppercase;
        font-weight: bold;
    }

    .myrobin-color-aqua {
        color: #0C9389 !important;
    }

    .highlight-component {
        background-color: rgba(245, 246, 246, 1) !important;
        color: rgba(55, 71, 79, 1) !important;
        border-radius: 0px 0px 10px 10px;
        padding: 10px;
    }

    .no-break {
        /* break-before: page; */
        display: block;
        break-inside: avoid;
    }

    #containerHtml p {
        color: #F44336 !important;
    }
}
</style>

<template>
    <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
        <div
            class="uk-grid-column-small uk-grid-row-large uk-child-width-1-2@m"
            uk-grid
        >
            <div v-if="getRole() !== 'supervisor'">
                <div>
                    <div uk-grid>
                        <div
                            class="uk-width-auto uk-margin-auto uk-margin-auto-vertical"
                        >
                            <img
                                @onerror="`${images}/avatar.svg`"
                                :src="
                                    list_detail &&
                                    list_detail.job_partner &&
                                    list_detail.job_partner.photo_url
                                        ? list_detail.job_partner.photo_url
                                              .file_url
                                        : `${images}/avatar.svg`
                                "
                                class="photo uk-border-circle"
                                width="100"
                                height="100"
                            />
                        </div>
                        <div
                            class="uk-width-expand uk-margin-auto uk-margin-auto-vertical"
                        >
                            <div>
                                <h4 class="uk-margin-remove">
                                    <span class="uk-text-bold">{{
                                        list_detail && list_detail.job_partner
                                            ? list_detail.job_partner.fullname
                                            : "-"
                                    }}</span>
                                </h4>
                            </div>
                            <span
                                >Job Role:
                                {{
                                    list_detail && list_detail.job_partner
                                        ? list_detail.job_partner.role
                                        : "-"
                                }}</span
                            ><br />
                            <span
                                >Company:
                                {{
                                    list_detail && list_detail.job_partner
                                        ? list_detail.job_partner.company_name
                                        : "-"
                                }}</span
                            ><br />
                            <span
                                >Office:
                                {{
                                    list_detail && list_detail.job_partner
                                        ? list_detail.job_partner.office_name
                                        : "-"
                                }}</span
                            ><br />
                        </div>
                    </div>
                </div>
                <h4 class="title">
                    Overtime data of
                    <span class="uk-text-bold">{{
                        list_detail && list_detail.job_partner
                            ? list_detail.job_partner.fullname
                            : "-"
                    }}</span>
                    for {{ moment(meta.start_date).format("DD MMM YYYY") }} -
                    {{ moment(meta.end_date).format("DD MMM YYYY") }}
                </h4>
            </div>
            <div>
                <div style="overflow: auto">
                    <div
                        class="report-download"
                        style="cursor: pointer; float: right"
                        @click="goBack()"
                    >
                        <img :src="`${images}/cross.svg`" alt="" width="25" />
                    </div>
                </div>
            </div>
        </div>

        <div class="uk-flex uk-flex-row uk-margin-small-top">
            <select
                id="officeFilter"
                class="uk-select uk-width-1-6 uk-margin-small-right"
                v-model="meta.company_office_id"
                @change="(e) => changeMeta(e, 'company_office_id')"
            >
                <option :value="null" selected>All Office</option>
                <option
                    v-for="(office, index) in company_offices"
                    :key="index"
                    :value="office._id"
                >
                    {{ office.name }}
                </option>
            </select>
            <select
                id="statusFilter"
                class="uk-select uk-width-auto uk-margin-small-right"
                :class="[meta.status == 0 ? 'bg-yellow' : '']"
                v-model="meta.status"
                @change="(e) => changeMeta(e, 'status')"
            >
                <option :value="null" selected>All Status</option>
                <option value="0">Waiting Verification</option>
                <option value="1">Verified</option>
                <option value="2">Rejected</option>
            </select>
            <select
                id="sortFilter"
                class="uk-select uk-width-auto"
                v-model="meta.sorting"
                @change="(e) => changeMeta(e, 'sorting')"
            >
                <option value="0" selected>Latest</option>
                <option value="1">Oldest</option>
            </select>
            <select
                id="methodFilter"
                class="uk-select uk-width-1-6 uk-margin-small-left"
                v-model="meta.method"
                @change="(e) => changeMeta(e, 'method')"
            >
                <option value="3">All Method</option>
                <option value="1">Bottom Up</option>
                <option value="2">Top Down</option>
            </select>
            <div
                v-if="meta.status == 0 && $can('EDIT', 'worker-overtime-report')"
                class="uk-width-expand uk-flex uk-flex-row uk-flex-right"
            >
                <button
                    class="action-button uk-button uk-button-primary uk-border-rounded uk-margin-small-left"
                    :disabled="selectedOvertime < 1"
                    @click="showConfirmation({ verify: true })"
                >
                    Verify
                </button>
                <button
                    class="action-button uk-button uk-button-danger uk-border-rounded uk-margin-small-left"
                    :disabled="selectedOvertime < 1"
                    @click="showConfirmation({ verify: false })"
                >
                    Reject
                </button>
                <button
                    class="action-button uk-button uk-button-default uk-border-rounded uk-margin-small-left"
                    @click="unselectAll"
                >
                    Cancel
                </button>
            </div>
        </div>

        <div
            v-if="isLoading"
            class="uk-flex uk-flex-center uk-flex-middle"
            style="min-height: 100%"
        >
            <div uk-spinner="ratio: 2"></div>
        </div>
        <div v-else class="uk-card uk-card-default uk-margin">
            <div class="uk-overflow-auto">
                <table class="uk-table uk-table-medium uk-table-striped">
                    <thead>
                        <th
                            v-if="isShowMultipleSelect && list_detail.docs.length > 0 && $can('EDIT', 'worker-overtime-report')"
                            class="uk-text-center"
                        >
                            <input
                                type="checkbox"
                                class="uk-checkbox uk-border-rounded"
                                :checked="selectedOvertime.length > 0"
                                @change="selectAll"
                            />
                        </th>
                        <th class="uk-text-left">Date</th>
                        <th class="uk-text-left">Status</th>
                        <th class="uk-text-left">Clock In</th>
                        <th class="uk-text-left">Clock Out</th>
                        <th class="uk-text-left">Overtime <br>Approved <br>(Hours)</th>
                        <th class="uk-text-left">Overtime <br>Assigned <br>(Hours)</th>
                        <th class="uk-text-left">Overtime Request <br>(Hours)</th>
                        <th class="uk-text-left">Attendance <br>Method</th>
                        <th class="uk-text-left">Overtime <br>Method</th>
                        <th class="uk-text-left">SPV Name</th>
                        <th class="uk-text-left">Attachment</th>
                        <!-- <th class="uk-text-left">SPV Action</th>
                        <th class="uk-text-left">SPV Action <br>Time</th> -->
                        <th class="uk-text-left">Nama Admin <br>Comdash</th>
                        <th class="uk-text-left">Time <br>Stamp<br></th>
                    </thead>
                    <LoadingTable v-if="isLoading" :colspan="14" />
                    <tbody v-else-if="list_detail && list_detail.docs.length > 0">
                        <tr v-for="(list, i) in list_detail.docs" :key="i">
                            <td
                                v-if="
                                    isShowMultipleSelect &&
                                    list_detail.docs.length > 0 &&
                                    $can('EDIT', 'worker-overtime-report')
                                "
                                class="uk-text-center"
                            >
                                <input
                                    type="checkbox"
                                    class="uk-checkbox uk-border-rounded"
                                    :checked="findSelectedOvertime({ _id: list._id })"
                                    :disabled="list.verify_status > 0"
                                    @change="onChangeSelectOvertime(list)"
                                />
                            </td>
                            <td class="uk-text-left">{{ moment(list.overtime_date).format("DD MMM YYYY") || "-" }}</td>
                            <td class="uk-text-left">{{ getStatusName(list.verify_status) || "-" }}</td>
                            <td class="uk-text-left">{{ moment(list.attendance_clock_in).format('HH:mm') || '-' }}</td>
                            <td class="uk-text-left">{{ moment(list.attendance_clock_out).format('HH:mm') || '-' }}</td>
                            <td class="uk-text-left">{{ list.overtime_duration_spv || 0 }}</td>
                            <td class="uk-text-left">{{ list.created_by == 'employer' ? list.overtime_duration : 0 }}</td>
                            <td class="uk-text-left">{{ list.overtime_duration || 0 }}</td>
                            <td class="uk-text-left">{{ filterAttendanceMethods(list.attendance_method) || '-'}}</td>
                            <td class="uk-text-left">{{ filterOvertimeMethod(list.overtime_method) }}</td>
                            <td class="uk-text-left">{{ list.spv_name ? list.spv_name : list.spv_action_by ? list.spv_action_by : '-' }}</td>
                            <td class="uk-text-left">
                                <a v-if="checkAttachment(list)" href="javascript:void(0)" style="text-decoration: none;" @click="openView(list, list_detail.job_partner)">
                                    {{ 'View' }}
                                </a>
                                <label v-else>No Attachment</label>
                            </td>
                            <!-- <td class="uk-text-left">{{ list.spv_action_by || '-' }}</td>
                            <td class="uk-text-left">{{ list.spv_action_at ? moment(list.spv_action_at).format("DD MMM YYYY, hh:mm:ss") : '-' }}</td> -->
                            <td class="uk-text-left">{{ list.verified_by || '-' }}</td>
                            <td class="uk-text-left">{{ list.verified_at ? moment(list.verified_at).format("DD MMM YYYY, hh:mm:ss") : '-' }}</td>
                        </tr>
                    </tbody>
                    <EmptyTable
                        v-else
                        :colspan="14"
                        :empty-text="'No entries found'"
                    />
                </table>
            </div>
            <pagination
                :total-data="list_detail.totalDocs"
                :change-limit="changeLimit"
                :change-page="changePage"
            />
        </div>

        <ConfirmationModal
            v-if="isShowConfirmationModal"
            :is-verify="isVerify"
            :start-date="dateCompleteStartDate"
            :end-date="dateCompleteEndDate"
            :selected-mitra="selectedOvertime"
            :is-multiple-mitra="false"
            :mitra-name="list_detail.job_partner.fullname"
            @hideModal="isShowConfirmationModal = false"
            @confirm="verifyOrReject"
        />

        <SPKLModal
            v-if="isShowSpklModal"
            :selected="selectedDetail"
            @hideModal="isShowSpklModal = false"
        />

        <PreviewImageModal
            v-if="modal.isShowPreviewImageModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewImageModal = false"
        />
        <PreviewPDFModal
            v-if="modal.isShowPreviewPDFModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewPDFModal = false"
        />
        <PreviewOfficeModal
            v-if="modal.isShowPreviewOfficeModal"
            :url="previewSrc"
            @hideModal="modal.isShowPreviewOfficeModal = false"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { PREFIX_IMAGE } from "@/utils/constant";
import { getUserLogin } from "@/utils/auth";
import formatter from "@/utils/formatter";
import { contentType } from '@/utils/helper';
import {
    notificationSuccess,
    notificationDanger,
    notificationDangerCustom
} from "@/utils/notification";
import moment from "moment";
moment.locale("id");

export default {
    components: {
        EmptyTable: () => import("@/components/globals/tables/EmptyTable"),
        LoadingTable: () => import("@/components/globals/tables/Loading"),
        Pagination: () => import("@/components/globals/Pagination"),
        ConfirmationModal: () => import("./Confirmation"),
        SPKLModal: () => import("./SPKLModal"),
        PreviewImageModal: () => import("@/components/globals/modals/ImageViewer"),
        PreviewPDFModal: () => import("@/components/globals/modals/PdfViewer"),
        PreviewOfficeModal: () => import("@/components/globals/modals/OfficeViewer"),
    },
    data({ $route }) {
        return {
            isLoading: false,
            images: PREFIX_IMAGE,
            meta: $route.query,
            moment: moment,
            selectedOvertime: [],
            isVerify: false,
            selectedDetail: null,
            isShowConfirmationModal: false,
            isShowSpklModal: false,
            selectMultiple: false,
            previewSrc: "",
            modal: {
                isShowPreviewImageModal: false,
                isShowPreviewPDFModal: false,
                isShowPreviewOfficeModal: false,
            }
        };
    },
    async mounted() {
        try {
            this.meta.sorting = this.$route.query.sorting == undefined ? 0 : this.$route.query.sorting;
            await this.getCompanyOffice();
            if (Object.keys(this.$route.query).length !== 0) {
                await this.getData();
            }
        } catch (error) {
            notificationDanger(error);
        }
    },
    computed: {
        ...mapGetters({
            list_detail: "overtime_report/list_detail",
            company_offices: "company_office/company_offices"
        }),
        user_cred() {
            return getUserLogin();
        },
        isShowMultipleSelect() {
            return Boolean(
                this.meta && this.meta.status && this.meta.status == 0
            );
        },
        dateCompleteStartDate() {
            return this.formatDate(this.meta.start_date);
        },
        dateCompleteEndDate() {
            return this.formatDate(this.meta.end_date);
        },
    },
    watch: {
        async meta() {
            this.isLoading = true;
            try {
                await this.getData();
            } catch (error) {
                notificationDanger(error);
            }
            this.isLoading = false;
        }
    },
    methods: {
        ...mapActions({
            getCompanyOffice: "company_office/getCompanyOffice",
            getDetailMitraReport: "overtime_report/getDetailMitraReport",
            verifyReject: "overtime_report/verifyReject"
        }),
        async getData() {
            const payload = {
                job_partner_id: this.$route.params.id,
                verify_status: this.meta.status,
                ...this.meta
            };
            await this.getDetailMitraReport(payload);
        },
        getRole() {
            return formatter.getUserRole().toLowerCase();
        },
        consist(user_roles, value) {
            return value.some((i) => user_roles.includes(i));
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value || null
            };
        },
        getStatusName(status) {
            let name = "";
            switch (status) {
            case 0:
                name = "Waiting Verification";
                break;
            case 1:
                name = "Verified";
                break;
            case 2:
                name = "Rejected";
                break;
            }
            return name;
        },
        filterAttendanceMethods(status){
            switch (status) {
            case 0:
                return 'Scanned by employer spv';
            case 1:
                return 'Self-scan on office/warehouse';
            case 2:
                return 'Photo upload';
            case 3:
                return 'Mitra Request';
            case 4:
                return 'HO';
            default:
                return 'No Attendance Methods';
            }
        },
        filterOvertimeMethod(status){
            switch (status) {
            case 1:
                return 'Bottom Up';
            case 2:
                return 'Top Down';
            case 3:
                return 'Mixed';
            default:
                return 'No Overtime Methods';
            }
        },
        formatDate(data) {
            if (data == null) {
                return "-";
            }
            return formatter.dateComplete(data);
        },
        unselectAll(){
            this.selectedOvertime = [];
            this.selectMultiple = false;
        },
        selectAll(){
            this.selectMultiple = !this.selectMultiple;
            if (Array.isArray(this.list_detail.docs) && this.list_detail.docs.length > 0 && this.selectMultiple) {
                for (const list of this.list_detail.docs){
                    const findSameData = this.selectedOvertime.find(
                        (item) => item?._id?.toString() === list?._id?.toString()
                    );
                    if (!findSameData) {
                        this.selectedOvertime.push(list);
                    }
                }
            } else {
                this.selectedOvertime = [];
            }
        },
        findSelectedOvertime({_id = null}){
            return !!this.selectedOvertime.find(
                (item) => item?._id.toString() == _id?.toString()
            );
        },
        onChangeSelectOvertime(data = null){
            let selected = [];
            const resultFindSameData = this.selectedOvertime.find(
                (item) => item?._id.toString() == data?._id?.toString()
            );
            selected = this.selectedOvertime.length > 0 ? resultFindSameData ? this.selectedOvertime.filter((item) => item._id != data._id) : [...this.selectedOvertime, ...[data]] : [data];
            this.selectedOvertime = selected;
            this.selectMultiple = selected.length > 0;
        },
        showConfirmation({verify=false}){
            this.isVerify = verify;
            this.isShowConfirmationModal = true;
        },
        async verifyOrReject({ data = null }){
            this.isLoading = true;
            const payload = {
                overtime_ids: data,
                verify_status: this.isVerify ? 1 : 2
            };
            this.unselectAll();
            const msg = this.isVerify ? 'approved': 'rejected';
            try {
                const response = await this.verifyReject(payload);
                if (response && response.status === 'OK') {
                    notificationSuccess(`Overtime request ${msg}!`);
                    this.getData();
                    this.unselectAll();
                } else {
                    notificationDangerCustom(`Failed to ${msg} overtime request!`);
                }
            } catch (error) {
                notificationDanger(error);
            }
            this.isShowConfirmationModal = false;
            this.isLoading = false;
        },
        showSpkl(data, job_partner){
            const detail = {
                ...job_partner,
                ...data
            };
            this.selectedDetail = detail;
            this.isShowSpklModal = true;
        },
        openView(data, job_partner){
            if (data.created_by == "employer") {
                this.showSpkl(data, job_partner);
            } else {
                this.previewSrc = data.document_attachment?.file_url;
                this.modal[contentType(this.previewSrc)] = true;
            }
        },
        checkAttachment(data){
            let isHave = false;
            if (data.created_by == "employer" || (data.created_by == "candidate"  && data.document_attachment && data.document_attachment.file_url)){
                isHave = true;
            }
            
            return isHave;
        },
        goBack(){
            this.$router.replace({ path: '/admin/workers/overtime-report/detail', query: this.meta });
        }
    }
};
</script>

<style scoped>
thead {
    background-color: #0abab5;
}
th {
    color: #ffffff;
}
td {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #000000;
}
.bg-yellow {
    background-color: #ffe500;
}
input[type="checkbox"] {
    border-color: #979797;
}
input[type="checkbox"]:not(:checked) {
    background-color: white;
}
input[type="checkbox"]:disabled {
    background-color: #cccccc;
}
.action-button:disabled {
    color: white;
    background-color: #979797 !important;
}
.photo {
    width: 100px; 
    height: 100px; 
    border-radius: 100%; 
    object-fit: cover;
    object-position: center;
}
</style>
